<template>
    <div>
        <div class="main-content" v-if="profile.accountPage">
            <div class="container">
                <div class="block block--account">
                    <div class="row">
                        <div class="col">
                            <span class="edit-button edit-button--small" data-testid="supplierNameEdit" @click="accountNameEdit = true" v-if="!accountNameEdit && accountInfo.repair_company_admin" style="right: 15px;"><i class="fas fa-pen"></i></span>
                            <span class="edit-button edit-button--small" data-testid="supplierNameSave" @click="saveAccountName()" v-if="accountNameEdit" style="right: 15px;"><i class="fas fa-save"></i></span>
                            <div class="profile-card" style="border-right: 0;">
                                <div class="row">
                                    <div class="col-3">
                                        <div class="profile-pic-wrapper">
                                            <span class="edit" data-testid="accountLogoEdit" @click="editLogo()" v-if="accountInfo.profile_image_loc && accountInfo.profile_image_loc !== '/images/no-profile-img.png'"><i class="fas fa-pen"></i></span>
                                            <span class="seperator" v-if="accountInfo.profile_image_loc && accountInfo.profile_image_loc !== '/images/no-profile-img.png'">|</span>
                                            <span class="remove" data-testid="accountLogoDelete" @click="removeLogo()" v-if="accountInfo.profile_image_loc && accountInfo.profile_image_loc !== '/images/no-profile-img.png'"><i class="far fa-trash-alt"></i></span>
                                            <img :src="accountInfo.profile_image_loc" class="profile-pic" v-if="accountInfo.profile_image_loc && accountInfo.profile_image_loc !== '/images/no-profile-img.png'" />
                                            <span class="add" @click="editLogo()" v-if="!accountInfo.profile_image_loc || accountInfo.profile_image_loc === '/images/no-profile-img.png'"><i class="fa-solid fa-plus"></i></span>
                                            <div class="profile-pic placeholder" v-if="!accountInfo.profile_image_loc || accountInfo.profile_image_loc === '/images/no-profile-img.png'">
                                                <i class="far fa-image"></i>
                                            </div>
                                            <input data-testid="accountLogoInput" type="file" id="profile-image-upload" style="display: none;" @change="uploadLogo($event)"/>
                                        </div>
                                    </div>
                                    <div class="col-9">
                                        <h2 data-testid="accountNameText" style="float: none;" v-if="!accountNameEdit">{{accountInfo.repair_company_name}}</h2>
                                        <input type="text" class="form-control" data-testid="newSupplierName" v-model="accountInfo.repair_company_name" v-if="accountNameEdit" />
                                        <h3 data-testid="accountEmailText" style="color: #9d9797; margin-top: 5px;">{{accountInfo.username}}</h3>
                                    </div>
                                </div>
                                <span class="notification notification--error" v-if="uploadError" style="margin-top: 20px;">{{profile.accountPage.account.upload_error}}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="block block--account" style="padding-bottom: 0;">
                            <div class="account-info">
                                <h4 style="margin-bottom: 0;">{{profile.accountPage.account.general}}</h4>
                                <div class="account-contents">
                                    <div class="info-row" data-testid="generalPasswordEditButton" @click="passwordEdit = true">
                                        <label><span v-if="!passwordEdit">{{profile.accountPage.account.password}}</span>
                                            <span class="edit-button edit-button--small" v-if="!passwordEdit"><i class="fas fa-pencil-alt"></i></span>
                                            <span class="edit-button edit-button--small" data-testid="generalPasswordSave" @click.stop="savePassword()" v-if="passwordEdit"><i class="far fa-save"></i></span>
                                        </label>
                                        <div v-if="passwordEdit">
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <label>{{profile.accountPage.account.new_pw}}</label>
                                                    <input data-testid="generalPasswordInput" type="password" class="form-control" v-model="newPassword" @input="passwordCheck()" />
                                                    <ul class="password-requirements" v-if="!passwordCorrect">
                                                        <li><i class="fas fa-times red" v-if="!passwordRequirements.characters"></i><i class="fas fa-check green" v-if="passwordRequirements.characters"></i> {{profile.accountPage.account.length}}</li>
                                                        <li><i class="fas fa-times red" v-if="!passwordRequirements.uppercase"></i><i class="fas fa-check green" v-if="passwordRequirements.uppercase"></i> {{profile.accountPage.account.ucase}}</li>
                                                        <li><i class="fas fa-times red" v-if="!passwordRequirements.lowercase"></i><i class="fas fa-check green" v-if="passwordRequirements.lowercase"></i> {{profile.accountPage.account.lcase}}</li>
                                                        <li><i class="fas fa-times red" v-if="!passwordRequirements.number"></i><i class="fas fa-check green" v-if="passwordRequirements.number"></i> {{profile.accountPage.account.nr}}</li>
                                                    </ul>
                                                </div>
                                                <div class="col-lg-6">
                                                    <label>{{profile.accountPage.account.confirm_new_pw}}</label>
                                                    <input data-testid="generalPasswordConfirmInput" type="password" class="form-control" v-model="newPasswordConfirm" @input="checkPasswords()" />
                                                    <p class="password-requirements" v-if="!passwordsMatch"><b>{{profile.accountPage.account.match}}</b></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else>
                                            <p>•••••••••</p>
                                        </div>
                                    </div>    
                                    <div class="info-row last" data-testid="generalEmailEditButton" @click="emailsEdit = true">
                                        <label>{{ profile.accountPage.account.email_addresses_supplier }} <i class="fas fa-info-circle" v-tooltip.right="profile.accountPage.account.receivers_tooltip_supplier" style="cursor: pointer;"></i>
                                            <span class="edit-button edit-button--small" v-if="!emailsEdit"><i class="fas fa-pencil-alt"></i></span>
                                            <span class="edit-button edit-button--small" data-testid="generalEmailSave" @click="saveEmails()" v-if="emailsEdit"><i class="far fa-save"></i></span>
                                        </label>
                                        <div v-if="emailsEdit">
                                            <input data-testid="generalEmailInput" type="text" class="form-control" v-model="accountInfo.repair_company_email_addresses" />
                                        </div>
                                        <div v-else>
                                            <p style="margin-bottom: 0;" data-testid="generalEmailText">{{ accountInfo.repair_company_email_addresses }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>     
                        <div class="block block--incident_detail location" style="min-height: 0; overflow: hidden;" v-if="accountInfo.service_qr_code">
                            <section class="incident-head">
                                <h1 style="max-width: 100%;">{{profile.accountPage.account.need_service}}</h1>
                            </section>
                            <img :src="'/api/v1/repaircompany/qrcodeasimage?qr_code=' + accountInfo.service_qr_code" class="qr-code" v-tooltip.right="profile.accountPage.account.service_qr" />
                            <a :href="'/open/onboarding/?qrcode=' + accountInfo.service_qr_code" target="blank" class="button button-qr-code"><i class="fas fa-concierge-bell" style="margin-right: 10px;"></i>{{profile.accountPage.account.ask_service}}</a>
                        </div>     
                    </div>
                    <div class="col-sm-6">
                        <div class="block block--account" style="padding-bottom: 0;">
                            <div class="account-info">
                                <h4>{{profile.accountPage.company.my_company}}
                                    <span class="edit-button edit-button--small" @click="companyInfoEdit = true" v-if="!companyInfoEdit && accountInfo.repair_company_admin"><i class="fas fa-pen"></i></span>
                                    <span class="edit-button edit-button--small" @click="saveCompanyInfo()" v-if="companyInfoEdit  && accountInfo.repair_company_admin"><i class="fas fa-save"></i></span>
                                </h4>
                                <div class="account-contents">
                                    <div class="info-row" @click="nameEdit = true">
                                        <label>{{profile.accountPage.account.contactname}}
                                            <span class="edit-button edit-button--small" v-if="!nameEdit"><i class="fas fa-pencil-alt"></i></span>
                                            <span class="edit-button edit-button--small" @click="saveName()" v-if="nameEdit"><i class="far fa-save"></i></span>
                                        </label>
                                        <div v-if="nameEdit">
                                            <input type="text" class="form-control" v-model="accountInfo.repair_company_contact_name" />
                                        </div>
                                        <div v-else>
                                            <p>{{accountInfo.repair_company_contact_name}}</p>
                                        </div>
                                    </div>
                                    <div class="info-row" @click="mobileEdit = true">
                                        <label>{{profile.accountPage.account.contactmobile}}
                                            <span class="edit-button edit-button--small" v-if="!mobileEdit"><i class="fas fa-pencil-alt"></i></span>
                                            <span class="edit-button edit-button--small" @click="saveMobile()" v-if="mobileEdit"><i class="far fa-save"></i></span>
                                        </label>
                                        <div v-if="mobileEdit">
                                            <input type="text" class="form-control" v-model="accountInfo.repair_company_mobile" @input="validateMobilePhone()" :class="{valid: validMobilePhone, error: invalidMobilePhone}" />
                                        </div>
                                        <div v-else>
                                            <p>{{accountInfo.repair_company_mobile}}</p>
                                        </div>
                                    </div>
                                    <div class="info-row" @click="phoneEdit = true">
                                        <label>{{ profile.accountPage.account.contactphone }}
                                            <span class="edit-button edit-button--small" v-if="!phoneEdit"><i class="fas fa-pencil-alt"></i></span>
                                            <span class="edit-button edit-button--small" @click="savePhone()" v-if="phoneEdit"><i class="far fa-save"></i></span>
                                        </label>
                                        <div v-if="phoneEdit">
                                            <input type="text" class="form-control" v-model="accountInfo.repair_company_phone"  @input="validateGeneralPhone()" :class="{valid: validGeneralPhone, error: invalidGeneralPhone}" />
                                        </div>
                                        <div v-else>
                                            <p>{{ accountInfo.repair_company_phone }}</p>
                                        </div>
                                    </div>
                                    <div class="info-row" @click="emailEdit = true">
                                        <label>{{ profile.incidents.new_incident.emailaddress }}
                                            <span class="edit-button edit-button--small" v-if="!emailEdit"><i class="fas fa-pencil-alt"></i></span>
                                            <span class="edit-button edit-button--small" @click="saveEmail()" v-if="emailEdit"><i class="far fa-save"></i></span>
                                        </label>
                                        <div v-if="emailEdit">
                                            <input type="text" class="form-control" v-model="accountInfo.repair_company_email" @input="validateEmail()" :class="{valid: validEmail, error: invalidEmail}" />
                                        </div>
                                        <div v-else>
                                            <p>{{ accountInfo.repair_company_email }}</p>
                                        </div>
                                    </div>
                                    <div class="info-row last" @click="industryEdit = true">
                                        <label>{{ profile.accountPage.account.industry }}
                                            <span class="edit-button edit-button--small" v-if="!industryEdit"><i class="fas fa-pencil-alt"></i></span>
                                            <span class="edit-button edit-button--small" @click="saveIndustry()" v-if="industryEdit"><i class="far fa-save"></i></span>
                                        </label>
                                        <div v-if="industryEdit">
                                            <div class="dropdown-section" style="margin-bottom: 20px;">
                                                <span class="dropdown" v-on:click="industryDropdownToggled = !industryDropdownToggled" :class="{active: industryDropdownToggled}">
                                                    <span v-if="!industry.display_name" style="color: #212529; font-size: 14px;">{{ profile.accountPage.account.industry }}</span>
                                                    <span v-else class="selected">{{industry.display_name}}</span>
                                                    <i class="fas fa-chevron-down chevron"></i>
                                                </span>
                                                <div class="dropdown-list" v-if="industryDropdownToggled">
                                                    <ul>
                                                        <li v-for="(industry, index) in industries" v-on:click="selectIndustry(industry)" :key="index">
                                                            {{industry.display_name}}
                                                        </li>        
                                                    </ul>
                                                </div>
                                            </div>                                        
                                        </div>
                                        <div v-else>
                                            <p>{{ industry.display_name }}</p>
                                        </div>
                                    </div>
                                    <hr style="margin: 0 -20px 20px -20px !important;"/>
                                    <label style="font-size: 18px; margin-bottom: 10px;">{{profile.accountPage.account.notifications_via_wa}}</label>
                                    <div class="info-row last no-radius" data-testid="phoneEditButton" @click="waEdit = true">
                                        <label>{{ profile.accountPage.account.contactmobile }}
                                            <span class="edit-button edit-button--small" v-if="!waEdit"><i class="fas fa-pencil-alt"></i></span>
                                            <span class="edit-button edit-button--small" data-testid="phoneSaveButton" @click="saveWaPhone()" v-if="waEdit"><i class="far fa-save"></i></span>
                                        </label>
                                        <div v-if="waEdit">
                                            <input data-testid="phoneNumberInput" type="text" class="form-control" v-model="accountInfo.updates_via_wa_phonenr" @input="validateWAPhone()" :class="{valid: validWAPhone, error: invalidWAPhone}" />
                                        </div>
                                        <div v-else>
                                            <p data-testid="phoneNumberText">{{ accountInfo.updates_via_wa_phonenr }}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="switch-wrapper switch-wrapper--full" :class="{disabled: !this.accountInfo.updates_via_wa_phonenr, active: accountInfo.new_incident_updates_via_wa_active}">
                                                <div class="inner" @click="toggleNotsWA()">
                                                    <i class="fa-brands fa-whatsapp"></i>
                                                    <p>{{profile.accountPage.account.new_incidents_wa_supplier}}</p>
                                                    <span class="label">{{profile.switches.wa_new}}</span>
                                                    <label class="switch">
                                                        <span data-testid="phoneNotificationSlider" class="slider round"></span>
                                                    </label>
                                                </div>     
                                            </div>                                     
                                        </div>
                                    </div>
                                </div>           
                            </div>
                        </div>
                    </div>
                </div>
                <EmployeesBlock :accountInfo="accountInfo" />
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-supplier.js';
import EmployeesBlock from "@/components/supplier/EmployeesBlock.vue";

export default {
    mixins: [setLocale],
    components: {
        EmployeesBlock
    },
    data() {
        return {
            profile: {},
            accountInfo: {},
            uploadError: false,
            passwordEdit: false,
            nameEdit: false,
            mobileEdit: false,
            phoneEdit: false,
            accountInfoEdit: false,
            emailsEdit: false,
            emailEdit: false,
            industryEdit: false,
            waEdit: false,
            newPassword: '',
            newPasswordConfirm: '',
            passwordRequirements: {
                characters: false,
                uppercase: false,
                lowercase: false,
                number: false
            },
            passwordCorrect: true,
            passwordsMatch: true,
            companyInfoEdit: false,
            industries: [],
            industry: {},
            industryDropdownToggled: false,
            validEmail: false,
            invalidEmail: false,
            validGeneralPhone: false,
            invalidGeneralPhone: false,
            validMobilePhone: false,
            invalidMobilePhone: false,
            validWAPhone: false,
            invalidWAPhone: false,
            canEditWA: false,
            accountNameEdit: false
        }
    },
    created() {
        document.title = 'S1MONE | Account';
        this.getAccountInfo();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getAccountInfo() {
            let self = this;
            fetch('/api/v1/userandrepaircompany')
            .then(response => { response.json().then(
                function(data) {
                    self.accountInfo = data;
                    self.getIndustries();
                });
            });
        },
        getIndustries() {
            let self = this;
            fetch('/api/v1/industry')
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.data.length; i++) {
                        let industry = data.data[i];
                        if (industry.display_name !== 'Sensors') {
                            self.industries.push(industry);
                        }
                    }
                    for (var i = 0; i < self.industries.length; i++) {
                        let industry = self.industries[i];
                        if (industry.constant_name === self.accountInfo.industry_constant) {
                            self.industry = industry;
                        }
                    }
                });
            });
        },
        selectIndustry(industry) {
            this.industry = industry;
            this.industryDropdownToggled = false;
        },
        editLogo() {
            if (this.accountInfo.repair_company_admin) {
                document.getElementById('profile-image-upload').click();
            }
        },
        uploadLogo($event) {
            if ($event.target.files.length == 1) {
                this.loading = true;
                const data = new FormData();
                data.append('file', $event.target.files[0]);
                let self = this;
                fetch('/api/v1/fileupload/uploadlogo', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    if (!data.includes('Error')) {
                        self.accountInfo.profile_image_loc = data;
                        self.uploadError = false;
                        self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    }
                    else {
                        self.uploadError = true;
                    }
                    self.loading = false;
                });
            }
        },
        saveAccountName() {
            if (this.accountInfo.repair_company_name.length) {
                const params = new URLSearchParams();
                params.append('action', 'edit');
                params.append('data[0][name]', this.accountInfo.repair_company_name);
                let self = this;
                fetch('/api/v1/repaircompany', {
                    method: 'post',
                    body: params
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.accountNameEdit = false;
                });
            }    
        },
        removeLogo() {
            const data = new URLSearchParams();
            let self = this;
            fetch('/api/v1/fileupload/deletelogo', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.accountInfo.profile_image_loc = '';
            });
        },
        savePassword() {
            if (this.passwordCorrect && this.passwordsMatch && this.newPassword.length) {
                const data = new URLSearchParams();
                data.append('newpassword', this.newPassword);
                let self = this;
                fetch('/api/v1/changepassword', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.passwordEdit = false;
                });
            }
            if (!this.newPassword.length) {
                this.$vToastify.success(this.profile.toast.edited, this.profile.toast.changes_saved);
                this.passwordEdit = false;
            }
        },
        saveEmails() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][email_addresses]', this.accountInfo.repair_company_email_addresses);
            let self = this;
            fetch('/api/v1/repaircompany', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.emailsEdit = false;
            });
        },
        cancelAccountInfo() {
            this.accountInfoEdit = false;
            this.newPassword = '';
            this.passwordConfirm = '';
            this.$emit('getAccountInfo');
        },
        passwordCheck() {
            const ucase = new RegExp("[A-Z]+");
            const lcase = new RegExp("[a-z]+");
            const num = new RegExp("[0-9]+");
            if (this.newPassword.length > 9) {
                this.passwordRequirements.characters = true;
            }
            else {
                this.passwordRequirements.characters = false;
                this.passwordCorrect = false;
            }
            if (ucase.test(this.newPassword)) {
                this.passwordRequirements.uppercase = true;
            }
            else {
                this.passwordRequirements.uppercase = false;
                this.passwordCorrect = false;
            }
            if (lcase.test(this.newPassword)) {
                this.passwordRequirements.lowercase = true;
            }
            else {
                this.passwordRequirements.lowercase = false;
                this.passwordCorrect = false;
            }
            if (num.test(this.newPassword)) {
                this.passwordRequirements.number = true;
            }
            else {
                this.passwordRequirements.number = false;
                this.passwordCorrect = false;
            }
            if (!this.newPassword.length) {
                this.passwordCorrect = true;
            }
            this.checkValidations();
        },
        checkValidations() {
            if (this.passwordRequirements.characters &&
                this.passwordRequirements.uppercase &&
                this.passwordRequirements.lowercase &&
                this.passwordRequirements.number &&
                this.newPassword === this.newPasswordConfirm) {
                    this.passwordCorrect = true;
            }
            else {
                this.passwordCorrect = false;
            }
            if (this.newPassword === this.newPasswordConfirm) {
                this.passwordsMatch = true;
            }
        },
        checkPasswords() {
            if (this.newPassword !== this.newPasswordConfirm) {
                this.passwordsMatch = false;
                this.passwordCorrect = false;
            }
            else {
                this.passwordsMatch = true;
            }
            this.checkValidations();
        },
        saveName() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][contact_name]', this.accountInfo.repair_company_contact_name);
            let self = this;
            fetch('/api/v1/repaircompany', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.nameEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveMobile() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][mobile]', this.accountInfo.repair_company_mobile);
            let self = this;
            if (!this.invalidMobilePhone) {
                fetch('/api/v1/repaircompany', {
                    method: 'post',
                    body: params
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.mobileEdit = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
        },
        savePhone() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][phone]', this.accountInfo.repair_company_phone);
            let self = this;
            if (!this.invalidGeneralPhone) {
                fetch('/api/v1/repaircompany', {
                    method: 'post',
                    body: params
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.phoneEdit = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
        },
        saveEmail() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][email]', this.accountInfo.repair_company_email);
            let self = this;
            if (!this.invalidEmail) {
                fetch('/api/v1/repaircompany', {
                    method: 'post',
                    body: params
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.emailEdit = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
        },
        saveIndustry() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[' + this.accountInfo.id_repair_company + '][industry_constant]', this.industry.constant_name);
            let self = this;
            fetch('/api/v1/repaircompany', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) { 
                self.industryEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        validateGeneralPhone() {
            if (this.accountInfo.repair_company_phone) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.accountInfo.repair_company_phone)) {
                    this.validGeneralPhone = true;
                    this.invalidGeneralPhone = false;
                }
                else {
                    this.invalidGeneralPhone = true;
                    this.validGeneralPhone = false;
                }
            }
            else {
                this.invalidGeneralPhone = false;
                this.validGeneralPhone = false;
            }
        },
        validateMobilePhone() {
            if (this.accountInfo.repair_company_mobile) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.accountInfo.repair_company_mobile)) {
                    this.validMobilePhone = true;
                    this.invalidMobilePhone = false;
                }
                else {
                    this.invalidMobilePhone = true;
                    this.validMobilePhone = false;
                }
            }
            else {
                this.invalidMobilePhone = false;
                this.validMobilePhone = false;
            }
        },
        validateEmail() {
            if (this.accountInfo.repair_company_email) {
                let pattern = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
                if (pattern.test(this.accountInfo.repair_company_email)) {
                    this.validEmail = true;
                    this.invalidEmail = false;
                }
                else {
                    this.invalidEmail = true;
                    this.validEmail = false;
                }
            }
            else {
                this.invalidEmail = false;
                this.validEmail = false;
            }
        },
        validateWAPhone() {
            if (this.accountInfo.updates_via_wa_phonenr) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.accountInfo.updates_via_wa_phonenr)) {
                    this.validWAPhone = true;
                    this.invalidWAPhone = false;
                }
                else {
                    this.invalidWAPhone = true;
                    this.validWAPhone = false;
                }
            }
            else {
                this.invalidWAPhone = false;
                this.validWAPhone = false;
            }
        },
        canEditWACheck() {
            if (!this.accountInfo.mobile) {
                this.canEditWA = false;
            }
            else {
                this.canEditWA = true;
            }
        },
        saveWaPhone() {
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][updates_via_wa_phonenr]', this.accountInfo.updates_via_wa_phonenr);
            let self = this;
            if (!this.invalidWAPhone) {
                fetch('/api/v1/repaircompany', {
                    method: 'post',
                    body: params
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.waEdit = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                });
            }
            if (!this.accountInfo.updates_via_wa_phonenr.length) {
                this.accountInfo.new_incident_updates_via_wa_active = false;
                this.toggleNotsWA();
            }
        },
        toggleNotsWA() {
            this.accountInfo.new_incident_updates_via_wa_active = !this.accountInfo.new_incident_updates_via_wa_active
            const params = new URLSearchParams();
            params.append('action', 'edit');
            params.append('data[0][new_incident_updates_via_wa_active]', this.accountInfo.new_incident_updates_via_wa_active);
            let self = this;
            fetch('/api/v1/repaircompany', {
                method: 'post',
                body: params
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        }
    }
}
</script>
