<template>
    <div>
        <div class="loading" v-if="loading"><img src="@/assets/icons/loading.gif" /></div>
        <div class="row">
            <div class="col">
                <div class="block block--incident_detail block--repaircompany" id="repblock">
                    <div class="incident-head" style="margin-bottom: 10px;">
                        <h1>{{profile.invoicing.commissioning}}</h1>
                    </div>
                    <div class="selected-repaircompany" :class="{'has-mechanic': incidentInfo.id_mechanic || (incidentInfo.canbe_id_repair_company !== incidentInfo.id_repair_company && incidentInfo.id_appointment) || (incidentInfo.canbe_id_repair_company === incidentInfo.id_repair_company && incidentInfo.id_appointment && !incidentInfo.id_mechanic)}" v-if="incidentInfo.id_repair_company">
                        <div v-if="incidentInfo.id_repair_company === incidentInfo.canbe_id_repair_company && incidentInfo.id_mechanic">
                            <div class="row">
                                <div class="col-sm-2">
                                    <img :src="incidentInfo.mechanic_profile_image_loc" class="repaircompany-logo" v-if="incidentInfo.mechanic_profile_image_loc" />
                                    <div class="logo-placeholder" v-else>
                                        <i class="far fa-image" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="col-sm-10">
                                    <p data-testid="commissionSpecialistInfoName1" style="float: left;">{{incidentInfo.mechanic_name}}</p>
                                    <span class="role-label role-label--specialist">{{profile.incidents.incidenttable.mechanic}}</span>
                                    <a v-if="incidentInfo.mechanic_mobile && incidentInfo.mechanic_mobile !== 'null'" :href="'tel:' + incidentInfo.mechanic_mobile" style="clear: both; display: block;"><i class="fas fa-mobile-alt" v-tooltip.right="incidentInfo.mechanic_mobile"></i>{{incidentInfo.mechanic_mobile}}</a>
                                    <div class="invoice-info">
                                        <div class="row">
                                            <div class="col">
                                                <label style="color: gray; width: 100%;">{{profile.invoicing.commissioned}} {{incidentInfo.appointment_created_full}}</label>
                                            </div>
                                        </div>
                                    </div>
                                    <span class="no_simone" v-if="incidentInfo.mechanic_only_email">{{profile.repair_companies.repaircompanydetail.onlyemailshort}}</span>
                                    <p class="invoice-toggler" data-testid="commissionOfferInfo1" :class="{active: quotationInfoToggled}" @click="quotationInfoToggled = !quotationInfoToggled" v-if="incidentInfo.quotation_value_supplier || incidentInfo.quotationUrl">{{profile.invoicing.quotation}}<a class="url-link" :href="incidentInfo.quotationUrl" target="_blank" v-if="incidentInfo.quotationUrl"><i class="fas fa-file-pdf" style="font-size: 18px;"></i></a> <i class="fas fa-chevron-down chevron"></i></p>
                                    <div class="invoice-info" v-if="quotationInfoToggled">
                                        <div class="row" v-if="incidentInfo.quotation_number_supplier">
                                            <div class="col">
                                                <label>{{profile.invoicing.quotation_number}}</label>
                                                <p data-testid="commissionOfferInfoNumber1">{{incidentInfo.quotation_number_supplier}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="incidentInfo.quotation_value_supplier">
                                            <div class="col">
                                                <label>{{profile.accountPage.account.amount}}</label>
                                                <p data-testid="commissionOfferInfoValue1">€ {{incidentInfo.quotation_value_supplier}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="invoice-toggler" data-testid="commissionValueInfo1" :class="{active: invoicingInfoToggled}" @click="invoicingInfoToggled = !invoicingInfoToggled" v-if="invoiceList.length">{{profile.invoicing.invoices}} ({{ invoiceList.length }})<i class="fas fa-chevron-down chevron"></i></p>
                                    <div class="invoice-info" v-if="invoicingInfoToggled && invoiceList.length">
                                        <div class="invoice-item" v-for="invoice in invoiceList" :key="invoice.id_supplier_invoice">
                                            <a class="url-link" :href="invoice.file_url" target="_blank" v-if="invoice.file_url">
                                                <div class="row" v-if="invoice.invoice_number">
                                                    <div class="col">
                                                        <label style="width: 120px;">{{profile.invoicing.invoice_number}}</label>
                                                        <p data-testid="commissionValueInfoNumber1">
                                                            {{invoice.invoice_number}}                       
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row" v-if="invoice.invoice_value">
                                                    <div class="col">
                                                        <label style="width: 120px; margin-bottom: 0;">{{profile.accountPage.account.amount}}</label>
                                                        <p data-testid="commissionValueInfoAmount1" style="margin-bottom: 0;">€ {{invoice.invoice_value}}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>                       
                                    </div>
                                    <div v-if="currentAppointment">
                                        <div class="incident-info wide" style="clear: both;">
                                            <div class="row">
                                                <div class="col">
                                                    <div v-if="incidentInfo.calendar_choice === 'choose_latest_possible'">
                                                        <label v-if="currentAppointmentDate" style="margin: 10px 0 0 0;">{{profile.incident_detail.choose_latest_possible}}</label>
                                                        <p data-testid="commissionSpecialistInfoDate1" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{currentAppointmentDate}}</p>
                                                    </div>
                                                    <div v-else>
                                                        <label v-if="currentAppointment" style="margin: 10px 0 0 0;">{{profile.incident_detail.latest_appointment}}</label>
                                                        <p data-testid="commissionSpecialistAppointment1" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{currentAppointment}}</p>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div v-if="currentAppointment && repairInfo.start">
                                                            <label style="margin: 10px 0 0 0;">{{profile.incident_detail.start_repair}}</label>
                                                            <p data-testid="commissionSpecialistInfoStart1" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{repairInfo.start}}</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div v-if="currentAppointment && repairInfo.start">
                                                            <label style="margin: 10px 0 0 0;">{{profile.incident_detail.end_repair}}</label>
                                                            <p data-testid="commissionSpecialistInfoEnd1" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{repairInfo.end}}</p>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-4">
                                                        <div v-if="currentAppointment && repairInfo.start">
                                                            <label style="margin: 10px 0 0 0;">{{profile.incident_detail.duration_repair}}</label>
                                                            <p data-testid="commissionSpecialistInfoPassed1" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{repairInfo.timeWorked}}</p>
                                                        </div>
                                                    </div>
                                                </div>     
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <div v-if="incidentInfo.pause_reason && incidentInfo.incident_status === 'paused_mechanic'">
                                                        <label style="margin: 10px 0 0 0;">{{profile.incident_detail.paused_incident_reason}}</label>
                                                        <p data-testid="commissionSpecialistInfoPauseReason1" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{incidentInfo.pause_reason}}</p>
                                                    </div>
                                                    <div v-if="incidentInfo.repaircompany_feedback" style="margin-top: 10px;">
                                                        <label>{{profile.incident_detail.feedback}} {{profile.incidents.incidenttable.repair_company}}</label>
                                                        <p data-testid="commissionSpecialistInfoFeedback1" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{incidentInfo.repaircompany_feedback}}</p>
                                                    </div>
                                                    <div v-if="incidentInfo.extra_mechanic" style="margin-top: 10px;">
                                                        <label>{{profile.incident_detail.feedback}} {{profile.incidents.incidenttable.mechanic}}</label>
                                                        <p data-testid="commissionSpecialistInfoExtra1" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{incidentInfo.extra_mechanic}}</p>
                                                    </div>
                                                </div>
                                            </div>                   
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr v-if="incidentInfo.incident_extra_repair_company" />
                            <label v-if="incidentInfo.incident_extra_repair_company" style="margin: 0;">{{profile.incident_detail.extra_mech}}</label>
                            <p v-if="incidentInfo.incident_extra_repair_company" style="font-size: 16px; font-weight: 100; margin-top: 0;">{{incidentInfo.incident_extra_repair_company}}</p>
                        </div>
                        <div v-if="(incidentInfo.id_repair_company && incidentInfo.id_repair_company !== incidentInfo.canbe_id_repair_company) || (incidentInfo.canbe_id_repair_company === incidentInfo.id_repair_company && incidentInfo.id_appointment && !incidentInfo.id_mechanic)">
                            <div class="row">
                                <div class="col-sm-3">
                                    <img :src="incidentInfo.repair_company_profile_image_loc" class="repaircompany-logo" v-if="incidentInfo.repair_company_profile_image_loc" />
                                    <div class="logo-placeholder" v-else>
                                        <i class="far fa-image" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="col-sm-9">
                                    <p data-testid="repairCompanyName" style="float: left; font-size: 21px;">{{incidentInfo.repair_company_name}}</p>
                                    <span class="role-label role-label--supplier">{{profile.invoicing.contractor}}</span>
                                    <a :href="'tel:' + incidentInfo.repair_company_mobile"  data-testid="repairCompanyPhone" class="tel" style="display: block; clear: both; text-decoration: none; color: #212529; font-weight: 500;" v-if="incidentInfo.repair_company_mobile"><i class="fas fa-phone-alt" style="margin-right: 5px;"></i>{{incidentInfo.repair_company_mobile}}</a>
                                    <div class="invoice-info" v-if="invoiceParty.date">
                                        <div class="row">
                                            <div class="col">
                                                <label style="color: #00C900; width: 100%;" v-if="incidentInfo.latest_fixed_agreed_repaircompany">{{profile.invoicing.completed_on}} {{incidentInfo.latest_fixed_agreed_repaircompany_date}}</label>
                                                <label style="color: gray; width: 100%;" v-else>{{profile.invoicing.commissioned}} {{invoiceParty.date}}</label>
                                                <label style="color: #bd0b0b; width: 100%; margin-top: -5px;" v-if="incidentInfo.incident_status === 'returned_to_buildingmanager' && incidentInfo.mandate_warning === 'no_warning'">{{profile.invoicing.commission_cancelled}} {{incidentInfo.last_status_full}}</label>
                                                <label data-testid="commissionViolationWarningText" style="color: #bd0b0b; width: 100%; margin-top: -5px;" v-if="incidentInfo.incident_status === 'returned_to_buildingmanager' && (incidentInfo.mandate_warning === 'quotation_amount' || incidentInfo.mandate_warning === 'target_price')">{{profile.invoicing.mandate_violated_on}} {{incidentInfo.last_status_full}}</label>
                                            </div>
                                        </div>
                                        <span class="status_reason" style="margin-bottom: 10px; padding-top: 0;" v-if="incidentInfo.returned_to_buildingmanager_text && incidentInfo.incident_status === 'returned_to_buildingmanager' && incidentInfo.mandate_warning === 'no_warning'"><b>{{profile.incident_detail.refuse_reason}}:</b> {{incidentInfo.returned_to_buildingmanager_text}}</span>
                                        <span class="status_reason" style="margin-bottom: 10px; padding-top: 0;" v-if="incidentInfo.repaircompany_feedback"><b>{{profile.incident_detail.feedback}} {{profile.incidents.incidenttable.repair_company}}:</b> {{incidentInfo.repaircompany_feedback}}</span>
                                    </div>
                                    <span class="no_simone" v-if="incidentInfo.repair_company_only_email">{{profile.repair_companies.repaircompanydetail.onlyemailshort}}</span>
                                    <span class="no_simone" v-if="incidentInfo.repair_company_no_communication">{{profile.repair_companies.repaircompanydetail.nocommshort}}</span> 
                                    <p class="invoice-toggler" data-testid="commissionMandateInfo" :class="{active: mandateInfoToggled}" @click="mandateInfoToggled = !mandateInfoToggled" v-if="incidentMandate && incidentMandate.amount" style="overflow: hidden;">
                                        <span style="float: left; font-weight: 500;">{{profile.mandates.mandate}}<i class="fas fa-exclamation-circle" style="margin: 4px 0 0 5px; color: #bd0b0b;" v-if="incidentInfo.mandate_warning === 'quotation_amount' || incidentInfo.mandate_warning === 'target_price'"></i></span>
                                        <i class="fas fa-chevron-down chevron"></i>
                                        <span class="button button--small button--outline" data-testid="commissionMandateInfoHistory" style="position: absolute; height: 25px; line-height: 25px; right: 40px; font-size: 10px;" @click="mandateHistoryModalOpen = true">
                                            <i class="fas fa-book" style="font-size: 10px; margin: 7px 0 0 3px;"></i>
                                            {{profile.mandates.mandate_log}}
                                        </span>
                                    </p>
                                    <div class="invoice-info" v-if="mandateInfoToggled && incidentMandate" @click="mandateInfoToggled = !mandateInfoToggled" style="position: relative; margin-bottom: 20px;">
                                        <div class="row">
                                            <div class="col" style="position: relative;">
                                                <label style="margin-top: 2px;">{{profile.mandates.mandate_amount}}</label>
                                                <p data-testid="commissionMandateInfoAmount" style="margin-top: 2px;">€ {{incidentMandate.amount}}
                                                    <span class="btw" v-if="incidentMandate.in_btw" data-testid="commissionMandateInfoBtwIn" style="margin-left: 5px; font-size: 11px; line-height: 11px;">Incl. BTW</span>
                                                    <span class="btw btw--out" data-testid="commissionMandateInfoBtwOut" v-else style="margin-left: 5px; font-size: 11px; line-height: 11px;">{{profile.mandates.out_btw}}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="incidentInfo.mandate_warning === 'quotation_amount' || incidentInfo.mandate_warning === 'target_price'">
                                            <div class="col" style="position: relative;">
                                                <label data-testid="commissionViolationOfferText" style="margin-top: 2px;" v-if="incidentInfo.mandate_warning === 'quotation_amount'">{{profile.invoicing.quotation_amount}}</label>
                                                <label data-testid="commissionViolatioTargetText" style="margin-top: 2px;" v-if="incidentInfo.mandate_warning === 'target_price'">{{profile.invoicing.target_price}}</label>
                                                <p data-testid="commissionViolationAmountText" style="margin-top: 2px;">€ {{incidentInfo.quotation_value_supplier}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="incidentInfo.mandate_warning === 'quotation_amount' || incidentInfo.mandate_warning === 'target_price'">
                                            <div class="col" style="position: relative;">
                                                <label style="margin-top: 2px;">{{profile.mandates.mandate_violation}}</label>
                                                <p data-testid="commissionViolationDifferenceText" style="margin-top: 2px;">€ {{incidentMandate.violationAmount}}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="invoice-toggler" data-testid="commissionInvoiceInfo" :class="{active: invoiceInfoToggled}" @click="invoiceInfoToggled = !invoiceInfoToggled" v-if="incidentInfo.canbe_id_repair_company !== incidentInfo.id_repair_company && invoiceParty.to_name">{{profile.invoicing.invoicing}} <i class="fas fa-chevron-down chevron"></i></p>
                                    <div class="invoice-info" v-if="invoiceInfoToggled" @click="invoiceInfoToggled = !invoiceInfoToggled" style="margin-bottom: 10px;">
                                        <div class="row">
                                            <div class="col">
                                                <label>{{profile.dashboard.building_manager.message_table.name}}</label>
                                                <p data-testid="commissionInvoiceInfoName">{{invoiceParty.to_name}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="invoiceParty.to_email">
                                            <div class="col">
                                                <label>{{profile.invoicing.invoice_email}}</label>
                                                <p data-testid="commissionInvoiceInfoEmail">{{invoiceParty.to_email}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="invoiceParty.to_address">
                                            <div class="col">
                                                <label>{{profile.invoicing.invoice_address}}</label>
                                                <p data-testid="commissionInvoiceInfoInvoice">{{invoiceParty.to_address}} {{invoiceParty.to_house_number}}, {{invoiceParty.to_zipcode}} {{invoiceParty.to_city}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="invoiceParty.to_phone">
                                            <div class="col">
                                                <label>{{profile.incidents.new_incident.phone}}</label>
                                                <p data-testid="commissionInvoiceInfoPhone">{{invoiceParty.to_phone}}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <label>{{profile.invoicing.prospect_in_advance}}</label>
                                                <p data-testid="commissionInvoiceInfoOfferYes" v-if="invoiceParty.offer_needed">{{profile.incident_detail.yes}}</p>
                                                <p data-testid="commissionInvoiceInfoOfferNo" v-else>{{profile.incident_detail.no}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="invoice-toggler" data-testid="commissionValueInfo2" :class="{active: invoicingInfoToggled}" @click="invoicingInfoToggled = !invoicingInfoToggled" v-if="invoiceList.length">{{profile.invoicing.invoices}} ({{ invoiceList.length }})<i class="fas fa-chevron-down chevron"></i></p>
                                    <div class="invoice-info" v-if="invoicingInfoToggled && invoiceList.length">
                                        <div class="invoice-item" v-for="invoice in invoiceList" :key="invoice.id_supplier_invoice">
                                            <a class="url-link" :href="invoice.file_url" target="_blank" v-if="invoice.file_url">
                                                <div class="row" v-if="invoice.invoice_number">
                                                    <div class="col">
                                                        <label style="width: 120px;">{{profile.invoicing.invoice_number}}</label>
                                                        <p data-testid="commissionValueInfoNumber2">
                                                            {{invoice.invoice_number}}                       
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="row" v-if="invoice.invoice_value">
                                                    <div class="col">
                                                        <label style="width: 120px; margin-bottom: 0;">{{profile.accountPage.account.amount}}</label>
                                                        <p data-testid="commissionValueInfoAmount2" style="margin-bottom: 0;">€ {{invoice.invoice_value}}</p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>                       
                                    </div>
                                    <p class="invoice-toggler" data-testid="commissionOfferInfo2" :class="{active: quotationInfoToggled}" @click="quotationInfoToggled = !quotationInfoToggled" v-if="incidentInfo.quotation_value_supplier || incidentInfo.quotationUrl">{{profile.invoicing.quotation}}<a class="url-link" :href="incidentInfo.quotationUrl" target="_blank" v-if="incidentInfo.quotationUrl"><i class="fas fa-file-pdf" style="font-size: 18px;"></i></a> <i class="fas fa-chevron-down chevron"></i></p>
                                    <div class="invoice-info" v-if="quotationInfoToggled">
                                        <div class="row" v-if="incidentInfo.quotation_number_supplier">
                                            <div class="col">
                                                <label>{{profile.invoicing.quotation_number}}</label>
                                                <p data-testid="commissionOfferInfoNumber2">{{incidentInfo.quotation_number_supplier}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="incidentInfo.quotation_value_supplier">
                                            <div class="col">
                                                <label>{{profile.accountPage.account.amount}}</label>
                                                <p data-testid="commissionOfferInfoAmount2">€ {{incidentInfo.quotation_value_supplier}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <p class="invoice-toggler" data-testid="commissionValueInfo2" :class="{active: invoicingInfoToggled}" @click="invoicingInfoToggled = !invoicingInfoToggled" v-if="incidentInfo.invoice_value_supplier || incidentInfo.invoiceUrl">{{profile.invoicing.invoice}}<a class="url-link" :href="incidentInfo.invoiceUrl" target="_blank" v-if="incidentInfo.invoiceUrl"><i class="fas fa-file-pdf" style="font-size: 18px;"></i></a> <i class="fas fa-chevron-down chevron"></i></p>
                                    <div class="invoice-info" v-if="invoicingInfoToggled">
                                        <div class="row" v-if="incidentInfo.invoice_number_supplier">
                                            <div class="col">
                                                <label>{{profile.invoicing.invoice_number}}</label>
                                                <p data-testid="commissionValueInfoNumber2">{{incidentInfo.invoice_number_supplier}}</p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="incidentInfo.invoice_value_supplier">
                                            <div class="col">
                                                <label>{{profile.accountPage.account.amount}}</label>
                                                <p data-testid="commissionValueInfoAmount2">€ {{incidentInfo.invoice_value_supplier}}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="currentAppointment">
                                        <div class="incident-info wide" style="margin-top: 15px;">
                                            <div class="row">
                                                <div class="col">
                                                    <div>
                                                        <label>{{profile.employees.mechanic}}</label>
                                                        <p data-testid="commissionSpecialistInfoName2" style="margin-bottom: 0; font-size: 16px; font-weight: 100;" v-if="incidentInfo.id_mechanic">{{incidentInfo.mechanic_name}}</p>
                                                        <p data-testid="commissionSpecialistInfoName2" style="margin-bottom: 0; font-size: 16px; font-weight: 100;" v-else>{{profile.incidents.incidenttable.mechanic_unknown}}</p>
                                                    </div>
                                                    <div v-if="incidentInfo.calendar_choice === 'choose_latest_possible'">
                                                        <label v-if="currentAppointmentDate" style="margin: 10px 0 0 0;">{{profile.incident_detail.choose_latest_possible}}</label>
                                                        <p data-testid="commissionSpecialistInfoDate2" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{currentAppointmentDate}}</p>
                                                    </div>
                                                    <div v-else>
                                                        <label v-if="currentAppointment" style="margin: 10px 0 0 0;">{{profile.incident_detail.latest_appointment}}</label>
                                                        <p data-testid="commissionSpecialistInfoAppointment2" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{currentAppointment}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">                                  
                                                <div class="col-sm-4">
                                                    <div v-if="currentAppointment && repairInfo.start">
                                                        <label style="margin: 10px 0 0 0;">{{profile.incident_detail.start_repair}}</label>
                                                        <p data-testid="commissionSpecialistInfoStart2" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{repairInfo.start}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div v-if="currentAppointment && repairInfo.start">
                                                        <label style="margin: 10px 0 0 0;">{{profile.incident_detail.end_repair}}</label>
                                                        <p data-testid="commissionSpecialistInfoEnd2" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{repairInfo.end}}</p>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div v-if="currentAppointment && repairInfo.start">
                                                        <label style="margin: 10px 0 0 0;">{{profile.incident_detail.duration_repair}}</label>
                                                        <p data-testid="commissionSpecialistInfoPassed2" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{repairInfo.timeWorked}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="incidentInfo.pause_reason && incidentInfo.incident_status === 'paused_mechanic'">
                                                <label style="margin: 10px 0 0 0;">{{profile.incident_detail.paused_incident_reason}}</label>
                                                <p data-testid="commissionSpecialistInfoPauseReason2" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{incidentInfo.pause_reason}}</p>
                                            </div>
                                            <div v-if="incidentInfo.repaircompany_feedback" style="margin-top: 10px;">
                                                <label>{{profile.incident_detail.feedback}} {{profile.incidents.incidenttable.repair_company}}</label>
                                                <p data-testid="commissionSpecialistInfoFeedback2" style="margin-bottom: 0; font-size: 16px; font-weight: 100;">{{incidentInfo.repaircompany_feedback}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-show="incidentInfo.incident_extra_building_manager && incidentInfo.canbe_id_repair_company !== incidentInfo.id_repair_company" class="additional">
                            <hr />
                            <label>{{profile.incident_detail.extra_info}}</label>
                            <p v-if="incidentInfo.incident_extra_building_manager">{{incidentInfo.incident_extra_building_manager}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <MandateHistoryModal v-if="mandateHistoryModalOpen" @close="mandateHistoryModalOpen = false" :incidentMandateLog="incidentMandateLog" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-mechanic.js';
import moment from 'moment';
import MandateHistoryModal from "@/components/modals/MandateHistoryModal.vue";

export default {
    name: 'Commission',
    mixins: [setLocale],
    components: {
        MandateHistoryModal
    },
    data: function () {
        return {
            loading: false,
            invoiceParty: {},
            invoiceInfoToggled: false,
            invoiceDropdownToggled: false,
            removeCommissionModalOpen: false,
            autodispatchType: null,  
            incidentMandate: {},
            mandateInfoToggled: false,
            mandateHistoryModalOpen: false,  
            quotationInfoToggled: false,
            invoicingInfoToggled: false,
            invoiceList: [],
            incidentMandateLog: []
        }
    },
    props: ['incidentInfo', 'currentAppointment', 'repairInfo'],
    created() {
        this.getInvoiceParty();
        this.getIncidentMandate();
        this.getInvoices();
    },
    methods: {
        getInvoices() {
            let self = this;
            fetch('/api/v1/order/getsupplierinvoices?id_incident=' + this.incidentInfo.id_incident)
            .then(response => { response.json().then(
                function(data) {
                    if (data.length) {
                        let invoices = [];
                        for (var i = 0; i < data.length; i++) {
                            let invoice = data[i];
                            if (self.locale === 'nl') {
                                invoice.date = moment(invoice.created).locale('nl').format('D MMM YYYY HH:mm:ss');
                                invoice.description = 'Factuur-' + invoice.invoice_number;
                            }
                            else {
                                invoice.date = moment(invoice.created).format('MMM D YYYY HH:mm:ss');
                                invoice.description = 'Invoice-' + invoice.invoice_number;
                            }
                            if (invoice.invoice_value && invoice.invoice_value.toString().includes('.')) {
                                invoice.invoice_value = (Math.round(invoice.invoice_value * 100) / 100).toFixed(2);
                                invoice.invoice_value = invoice.invoice_value.toString().replaceAll('.', ',');
                            } 
                            invoices.push(invoice);
                        }
                        // For now we only support one invoice, we need to update this later
                        self.invoiceList = invoices;
                    }                 
                });
            });
        },
        getInvoiceParty() {
            this.selectedType = '';
            let self = this;
            fetch('/api/v1/invoice_data/byincident?id_incident=' + this.incidentInfo.id_incident)
            .then(response => { response.json().then(
                function(data) {
                    self.invoiceParty = data;
                    if (self.invoiceParty.created) {
                        self.invoiceParty.date = moment(self.invoiceParty.created).format('DD/MM/YYYY HH:mm:ss');
                    }
                    self.loading = false;
                });
            });
        },
        getIncidentMandate() {
            let self = this;
            fetch('/api/v1/incidentmandate/byincident?id_incident=' + this.incidentInfo.id_incident)
            .then(response => { response.json().then(
                function(data) {
                    self.incidentMandateLog = data;
                    self.selectedMandate = {};
                    self.mandateAmountError = false;
                    for (var i = 0; i < data.length; i++) {
                        let mandate = data[i];
                        mandate.date = moment(mandate.created).format('DD/MM/YYYY HH:mm:ss');
                        if (mandate.active) {
                            self.incidentMandate = mandate;
                        }
                    }
                    if (self.incidentMandate.amount && self.incidentMandate.amount.toString().includes('.')) {
                        self.incidentMandate.amount = (Math.round(self.incidentMandate.amount * 100) / 100).toFixed(2)
                    }
                    self.incidentMandate.violationAmount = self.incidentInfo.quotation_value_supplier - self.incidentMandate.amount;
                });
            });
        },
        getUrlParameter(name) {
            return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[""])[1].replace(/\+/g, '%20'))||null;
        }
    }
}
</script>
