<template>
    <div>
        <div class="loading" v-if="loading"><img src="@/assets/icons/loading.gif" /></div>
        <div class="main-content" v-if="profile.statusses">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <router-link tag="a" to="/supplier" class="back-link"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</router-link>
                    </div>
                </div>
                <div class="row" v-if="(incidentInfo.mandate_warning === 'no_warning' || incidentInfo.mandate_warning === 'mandate_increase') && incidentInfo.incident_status !== 'fixed_agreed_repaircompany' && incidentInfo.incident_status !== 'closed' && incidentInfo.incident_status !== 'cancelled'">
                    <div class="col" style="position: relative;">
                        <div class="block block--incident_detail block--actions">
                            <div class="row">
                                <div class="col">
                                    <span v-if="incidentInfo.serviced_by_rep_user_id" class="current-userlabel" v-tooltip.right="profile.incident_detail.incident_who + ' ' + incidentInfo.serviced_by_rep_user_first_name"><i class="fas fa-user"></i> {{incidentInfo.serviced_by_rep_user_first_name}}</span>
                                    <span v-if="!incidentInfo.serviced_by_rep_user_id" class="current-userlabel"><i class="fas fa-user-slash"></i> {{profile.incident_detail.not_in_use}}</span>
                                </div>
                            </div>
                            <div class="incident-head">
                                <h1 style="font-size: 21px;">{{profile.incident_detail.available_actions}}</h1>
                            </div>
                            <div class="actions" v-if="incidentInfo.incident_status !== 'closed'">
                                <div class="main-buttons">
                                    <span data-testid="incidentActionCalendar" class="button button--cta button--icon" @click="scrollCalendar()" v-if="!incidentInfo.id_appointment && incidentInfo.serviced_by_rep_user_id"><i class="fas fa-calendar-day"></i>{{profile.incident_detail.create_appointment}}</span>
                                    <span data-testid="incidentActionStart" class="button button--cta button--icon" @click="startIncident()" v-if="newIncident"><i class="fas fa-play"></i>{{profile.incident_detail.activate_assignment}}</span>
                                    <span data-testid="incidentActionFinish" class="button button--cta button--icon" @click="finishIncidentModalOpen = true" v-if="readyToFinish && incidentInfo.incident_status !== 'new_repaircompany'"><i class="fas fa-handshake"></i>{{profile.incident_detail.agree_close_supplier}}</span>
                                </div>
                            </div>
                            <br v-if="incidentInfo.incident_status === 'closed'" />
                            <br v-if="incidentInfo.incident_status === 'closed'" />
                            <br v-if="incidentInfo.incident_status === 'closed'" />
                            <div class="sub-buttons-wrap" v-if="!readOnly || incidentInfo.incident_status === 'closed'">
                                <span data-testid="incidentActionDropdown" class="sub-button-dropdown" :class="{active: subButtonsToggled}" @click="subButtonsToggled = !subButtonsToggled">{{profile.incident_detail.other_actions}} <i class="fa fa-chevron-down"></i></span>
                                <div class="sub-buttons" :class="{active: subButtonsToggled}" v-if="subButtonsToggled">
                                    <span v-if="incidentInfo.incident_status !== 'closed'" data-testid="incidentActionRefuse" class="button button--small button--icon" @click="refuseWorkModalOpen = true"><i class="fa fa-times"></i>{{profile.invoicing.refuse_assignment}}</span>
                                    <span data-testid="incidentActionViolate" class="button button--small button--icon" @click="mandateViolationModalOpen = true" v-if="activeMandate && activeMandate.amount && incidentInfo.building_manager_mandate && incidentInfo.incident_status !== 'closed'"><i class="fas fa-exclamation"></i>{{profile.mandates.mandate_violation}}</span>
                                    <span v-if="incidentInfo.incident_status !== 'closed'" class="button button--small button--icon" @click="uploadQuotationModalOpen = true"><i class="fas fa-file-pdf"></i>{{profile.invoicing.add_quotation}}</span>
                                    <span class="button button--small button--icon" @click="uploadInvoiceModalOpen = true"><i class="fas fa-file-pdf"></i>{{profile.invoicing.add_invoice}}</span>
                                    <div v-if="manualCheckIn || manualCheckOut || manualCheckInOut && incidentInfo.incident_status !== 'closed'" class="extra-buttons" style="margin-right: 10px;">
                                        <span data-testid="incidentActionCheckIn" class="button button--neutral button--icon" @click="manualCheckinModalOpen = true" v-if="manualCheckIn"><i class="fas fa-play"></i>{{profile.incident_detail.on_location_mechanic}}</span>
                                        <span data-testid="incidentActionCheckOut" class="button button--neutral button--icon" @click="manualPauseModalOpen = true" v-if="manualCheckOut"><i class="fas fa-pause"></i>{{profile.incident_detail.paused_mechanic}}</span>
                                        <span data-testid="incidentActionCheckOut2" class="button button--neutral button--icon" @click="manualCheckoutModalOpen = true" v-if="manualCheckOut"><i class="fas fa-stop"></i>{{profile.incident_detail.fixed_mechanic}}</span>
                                        <span data-testid="incidentActionCheckInOut" class="button button--neutral button--icon" @click="manualCheckInOutModalOpen = true" v-if="manualCheckInOut"><i class="fas fa-play"></i>{{profile.incident_detail.checkout_mechanic_repair}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="incidentInfo.incident_status === 'closed' || incidentInfo.incident_status === 'fixed_agreed_repaircompany'">
                    <div class="col" style="position: relative;">
                        <div class="block block--incident_detail block--actions">
                            <div class="incident-head">
                                <h1 style="font-size: 21px;">{{profile.incident_detail.available_actions}}</h1>
                            </div>
                            <div class="actions">
                                <div class="main-buttons">
                                    <span class="button button--cta button--icon" data-testid="adminReadyButton" @click="saveAdminReady()" v-if="incidentInfo.incident_status === 'fixed_agreed_repaircompany' && !incidentInfo.admin_done"><i class="fas fa-handshake"></i>{{profile.incident_detail.admin_ready}}</span>
                                    <span class="button button--icon" data-testid="addInvoiceButton" @click="uploadInvoiceModalOpen = true"><i class="fas fa-file-pdf"></i>{{profile.invoicing.add_invoice}}</span>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
                <div class="row" v-if="incidentInfo.original_id_building_manager">
                    <div class="col">
                        <span data-testid="incidentActionPass" class="original_bm" v-tooltip.right="profile.incident_detail.pass_tooltip"><i class="far fa-id-card"></i> {{incidentInfo.original_building_manager_name}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <div class="block block--incident_detail" :class="{tabbed: incidentInfo.original_id_building_manager}" style="padding-bottom: 0;">
                            <section class="incident-head">
                                <div class="row">
                                    <div class="col-sm-9 col-md-10">
                                        <span data-testid="incidentDate" class="date">{{incidentInfo.day}} {{incidentInfo.date}}, {{incidentInfo.time}}</span>
                                        <span data-testid="incidentStatus" class="status-bar" v-if="incidentInfo.incident_status && !profile['statusses'][incidentInfo.incident_status].repaircompany">
                                            <div v-html="profile['status_icons'][incidentInfo.incident_status]" v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic'"></div>
                                            <div v-html="profile['status_icons'][incidentInfo.incident_status]" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason"></div>
                                            <span data-testid="incidentLabelPaused" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason">{{profile['statusses'][incidentInfo.incident_status]}}</span>
                                            <span data-testid="incidentLabelCancelled" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'cancelled'" v-tooltip.right="incidentInfo.cancelled_reason">{{profile['statusses'][incidentInfo.incident_status]}}</span>
                                            <span data-testid="incidentLabelPauseCancelled" v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic' && incidentInfo.incident_status !== 'cancelled'">{{profile['statusses'][incidentInfo.incident_status]}}</span>
                                        </span>
                                        <span data-testid="incidentStatus2" class="status-bar" v-if="incidentInfo.incident_status && profile['statusses'][incidentInfo.incident_status].repaircompany">
                                            <div v-html="profile['status_icons'][incidentInfo.incident_status].repaircompany" v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic' && profile['status_icons'][incidentInfo.incident_status].repaircompany"></div>
                                            <div v-html="profile['status_icons'][incidentInfo.incident_status]" v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic' && !profile['status_icons'][incidentInfo.incident_status].repaircompany"></div>
                                            <div v-html="profile['status_icons'][incidentInfo.incident_status].repaircompany" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic' && profile['status_icons'][incidentInfo.incident_status].repaircompany" v-tooltip.right="incidentInfo.pause_reason"></div>
                                            <div v-html="profile['status_icons'][incidentInfo.incident_status]" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason"></div>
                                            <span data-testid="incidentLabelPaused2" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'paused_mechanic'" v-tooltip.right="incidentInfo.pause_reason">{{profile['statusses'][incidentInfo.incident_status].repaircompany}}</span>
                                            <span data-testid="incidentLabelCancelled2" v-if="incidentInfo.incident_status && incidentInfo.incident_status === 'cancelled'" v-tooltip.right="incidentInfo.cancelled_reason">{{profile['statusses'][incidentInfo.incident_status].repaircompany}}</span>
                                            <span data-testid="incidentLabelPauseCancelled2" v-if="incidentInfo.incident_status && incidentInfo.incident_status !== 'paused_mechanic' && incidentInfo.incident_status !== 'cancelled'">{{profile['statusses'][incidentInfo.incident_status].repaircompany}}</span>
                                        </span>
                                        <span class="time-indicator" :class="incidentInfo.overtimeStatus"><i class="fas fa-stopwatch"></i>{{profile.incidents.incidenttable.time_in_status}} {{incidentInfo.overtime}}</span>
                                    </div>
                                </div>
                                <h1 data-testid="incidentIdText" class="incident_nr">{{incidentInfo.id_incident}}</h1>
                                <span data-testid="incidentLabelFollowUp" class="followup" v-if="incidentInfo.wants_copy"><i class="fas fa-calendar-plus"></i> {{profile.incident_detail.followup_label}}</span>
                                <span data-testid="incidentLabelViolate" class="mandate-violation active" v-if="incidentInfo.incident_status === 'returned_to_buildingmanager' && incidentInfo.mandate_warning === 'no_warning'"><i class="fa fa-exclamation"></i> {{profile.invoicing.commission_cancel}}</span>
                                <span data-testid="incidentLabelViolate2" class="mandate-violation active" v-if="incidentInfo.mandate_warning === 'quotation_amount' || incidentInfo.mandate_warning === 'target_price'"><i class="fa fa-exclamation"></i> {{profile.mandates.mandate_violation}}</span>
                                <span data-testid="incidentLabelViolate2" class="mandate-increase active" v-if="incidentInfo.mandate_warning === 'mandate_increase'" v-tooltip.right="mandateIncreaseTooltip"><i class="fas fa-euro-sign"></i> {{profile.mandates.mandate_increase}}</span>
                                <span data-testid="incidentLabelOffer" class="mandate-violation active" v-if="invoiceParty.offer_needed">{{profile.invoicing.prospect_in_advance}}</span>
                                <span data-testid="incidentLabelCopied" class="copy-indicator" @click="connectedIncidentsModalOpen = true" v-if="incidentInfo.copiedFrom && incidentInfo.copiedFrom[0]">{{incidentInfo.copiedFrom[0].id_ori_incident}} <i class="fas fa-copy"></i></span>
                                <span data-testid="incidentLabelCopied2" class="copy-indicator" @click="connectedIncidentsModalOpen = true" v-if="incidentInfo.copiedTo && incidentInfo.copiedTo[0]">{{incidentInfo.copiedTo[0].id_ori_incident}} <i class="fas fa-copy"></i></span>
                                <div class="copy-indicator" v-if="incidentInfo.canbe_id_repair_company === incidentInfo.id_repair_company"><i class="fas fa-exclamation-circle"></i> {{ profile.accountPage.account.own_service }}</div>
                            </section>
                            <section class="incident-contents">
                                <div class="incident-info">
                                    <div class="info-row">
                                        <label>{{profile.incident_detail.category}}</label>
                                        <p data-testid="incidentCategoryText" v-if="locale === 'nl' && incidentInfo.incidentCategory">{{incidentInfo.incidentCategory.dutch_name}}</p>
                                        <p data-testid="incidentCategoryEnText" v-if="locale === 'en' && incidentInfo.incidentCategory">{{incidentInfo.incidentCategory.english_name}}</p>
                                    </div>
                                    <div class="info-row last" v-if="incidentInfo.incident_description">
                                        <label>{{profile.incident_detail.description}}</label>
                                        <p data-testid="incidentDescriptionText" style="margin-bottom: 0;">{{incidentInfo.incident_description}}</p>
                                    </div>
                                </div>
                                <div class="incident-info wide" v-if="incidentInfo.reporter_feedback || incidentInfo.repaircompany_feedback || incidentInfo.extra_mechanic">
                                    <div class="row">
                                        <div class="col-sm-3" v-if="incidentInfo.reporter_feedback">
                                            <label>{{profile.incident_detail.feedback}} {{profile.incident_detail.no_chat_name}}</label>
                                            <p data-testid="incidentFeedbackReporter">{{incidentInfo.reporter_feedback}}</p>
                                        </div>
                                        <div class="col-sm-3" v-if="incidentInfo.repaircompany_feedback">
                                            <label>{{profile.incident_detail.feedback}} {{profile.incidents.incidenttable.repair_company}}</label>
                                            <p data-testid="incidentFeedbackCompany">{{incidentInfo.repaircompany_feedback}}</p>
                                        </div>
                                        <div class="col-sm-3" v-if="incidentInfo.extra_mechanic">
                                            <label>{{profile.incident_detail.feedback}} {{profile.incidents.incidenttable.mechanic}}</label>
                                            <p data-testid="incidentMechanic">{{incidentInfo.extra_mechanic}}</p>
                                        </div>
                                    </div>
                                </div>
                                <label v-if="incidentInfo.buildingmanager_feedback">{{profile.incident_detail.remark}}</label>
                                <p v-if="incidentInfo.buildingmanager_feedback">{{incidentInfo.buildingmanager_feedback}}</p>
                                <div class="row">
                                    <div class="col" style="cursor: pointer;">
                                        <span class="urgent-label" data-testid="incidentLabelUrgent" v-if="incidentInfo.urgency_level"><i class="fas fa-exclamation-circle"></i> URGENT</span>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-8">
                        <div class="block block--incident_detail location" style="width: 100%; overflow: hidden;">
                            <section class="incident-head">
                                <h1 data-testid="incidentInvoicing">{{profile.invoicing.job}}</h1>
                            </section>
                            <label v-if="incidentInfo.incident_extra_building_manager">{{profile.extra_information}}</label>
                            <p v-if="incidentInfo.incident_extra_building_manager">{{incidentInfo.incident_extra_building_manager}}</p>
                            <div class="info-row" v-if="incidentInfo.serviced_by_bm_user_id">
                                <label>{{ profile.incidents.incidenttable.name_client }}</label>
                                <p>{{incidentInfo.serviced_by_bm_user_first_name}}</p>
                            </div>
                            <div class="info-row" v-if="activeMandate.amount">
                                <label>{{profile.mandates.mandate_amount}}</label>
                                <p data-testid="incidentInvoiceAmount" v-if="incidentInfo.mandate_warning === 'mandate_increase'">{{mandateIncreaseTooltip}} <span v-if="activeMandate.in_btw">({{profile.mandates.in_btw}})</span><span v-else>({{profile.mandates.out_btw}})</span></p>
                                <p data-testid="incidentInvoiceAmount" v-else>€ {{ activeMandate.amount }} <span v-if="activeMandate.in_btw">({{profile.mandates.in_btw}})</span><span v-else>({{profile.mandates.out_btw}})</span></p>
                            </div>
                           <div class="info-row" v-if="incidentInfo.quotation_number_supplier">
                                <label>{{profile.invoicing.quotation_number}}</label>
                                <p data-testid="incidentInvoiceQuotation">{{ incidentInfo.quotation_number_supplier }}</p>
                           </div>
                           <div class="info-row" v-if="incidentInfo.quotation_value_supplier">
                                <label>{{profile.invoicing.quotation_amount}}</label>
                                <p data-testid="incidentInvoiceSupplier">€ {{ incidentInfo.quotation_value_supplier }}</p>
                           </div>
                           <div class="info-row" v-if="violationRemark && violationRemark.content">
                                <label>{{ profile.mandates.violation_remark }}</label>
                                <p data-testid="incidentInvoiceViolation">{{ violationRemark.content }}</p>
                           </div>
                           <div class="info-row" v-if="invoiceParty.default_text" style="margin-bottom: 15px;">
                                <label>{{ profile.invoicing.standard_text }}</label>
                                <p data-testid="incidentInvoiceDefault">{{ invoiceParty.default_text }}</p>
                           </div>
                            <p class="invoice-toggler" :class="{active: quotationInfoToggled}" @click="quotationInfoToggled = !quotationInfoToggled" v-if="incidentInfo.quotation_value_supplier || incidentInfo.quotationUrl">
                                <span style="font-size: 16px; font-weight: 500;"><span v-if="incidentInfo.mandate_warning === 'target_price'" style="font-weight: 500;">{{profile.invoicing.target_price}}</span><span style="font-weight: 500;" v-else>{{profile.invoicing.quotation}}</span><a class="url-link" :href="incidentInfo.quotationUrl" target="_blank" v-if="incidentInfo.quotationUrl" style="margin: -3px 0 -6px 5px;" v-tooltip.right="profile.invoicing.click_open_file"><i class="fas fa-file-pdf" style="font-size: 18px;"></i></a> <i class="fas fa-chevron-down chevron"></i></span>
                            </p>
                            <div class="invoice-info" v-if="quotationInfoToggled" style="margin-bottom: 10px;">
                                <div class="row" v-if="incidentInfo.quotation_number_supplier && incidentInfo.mandate_warning !== 'target_price'">
                                    <div class="col">
                                        <label>{{profile.invoicing.quotation_number}}</label>
                                        <p>{{incidentInfo.quotation_number_supplier}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="incidentInfo.quotation_value_supplier">
                                    <div class="col">
                                        <label>{{profile.accountPage.account.amount}}</label>
                                        <p>€ {{incidentInfo.quotation_value_supplier}}</p>
                                    </div>
                                </div>
                            </div>
                            <p class="invoice-toggler" :class="{active: invoiceInfoToggled}" @click="invoiceInfoToggled = !invoiceInfoToggled" v-if="invoiceParty.to_name" style="overflow: hidden;">
                                <span style="font-size: 16px; font-weight: 500; float: left;">{{profile.invoicing.invoicing}}</span>
                                <span data-testid="incidentInvoiceHistory" class="button button--small button--outline" style="position: absolute; height: 25px; line-height: 25px; right: 40px; font-size: 10px;" @click="mandateHistoryModalOpen = true" v-if="incidentMandateLog.length">
                                    <i class="fas fa-book" style="font-size: 10px; margin: 7px 0 0 3px;"></i>
                                    {{profile.mandates.mandate_log}}
                                </span>
                                <i class="fas fa-chevron-down chevron"></i>
                            </p>
                            <div class="invoice-info" v-if="invoiceInfoToggled" @click="invoiceInfoToggled = !invoiceInfoToggled" style="margin-bottom: 10px;">
                                <div class="row">
                                    <div class="col">
                                        <label>{{profile.invoicing.prospect_in_advance}}</label>
                                        <p data-testid="incidentInvoiceOffer" v-if="invoiceParty.offer_needed">{{profile.incident_detail.yes}}</p>
                                        <p data-testid="incidentInvoiceOffer2" v-else>{{profile.incident_detail.no}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <label>{{profile.dashboard.building_manager.message_table.name}}</label>
                                        <p data-testid="incidentInvoiceName">{{invoiceParty.to_name}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="invoiceParty.to_email">
                                    <div class="col">
                                        <label>{{profile.invoicing.invoice_email}}</label>
                                        <p data-testid="incidentInvoiceEmail">{{invoiceParty.to_email}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="invoiceParty.to_address">
                                    <div class="col">
                                        <label>{{profile.invoicing.invoice_address}}</label>
                                        <p data-testid="incidentInvoiceAddress">{{invoiceParty.to_address}} {{invoiceParty.to_house_number}}, {{invoiceParty.to_zipcode}} {{invoiceParty.to_city}}</p>
                                    </div>
                                </div>
                                <div class="row" v-if="invoiceParty.to_phone">
                                    <div class="col">
                                        <label>{{profile.incidents.new_incident.phone}}</label>
                                        <p data-testid="incidentInvoicePhone">{{invoiceParty.to_phone}}</p>
                                    </div>
                                </div>
                            </div>
                            <p class="invoice-toggler" style="font-size: 16px; font-weight: 500;" :class="{active: invoicingInfoToggled}" @click="invoicingInfoToggled = !invoicingInfoToggled" v-if="invoiceList.length">{{profile.invoicing.invoices}} ({{ invoiceList.length }})<i class="fas fa-chevron-down chevron"></i></p>
                            <div class="invoice-info" v-if="invoicingInfoToggled && invoiceList.length" style="overflow: hidden;">
                                <div class="invoice-item" v-for="invoice in invoiceList" :key="invoice.id_supplier_invoice" v-tooltip.right="profile.invoicing.click_open_file">
                                    <span class="edit-button icon" @click="removeInvoice(invoice)">
                                        <i class="far fa-trash-alt"></i>
                                    </span>
                                    <span class="edit-button icon" @click="editInvoice(invoice)" style="right: 25px;">
                                        <i class="fas fa-pen" style="font-size: 14px;"></i>
                                    </span>
                                    <span style="color: #6a6a6f;font-weight: 500;font-size: 12px; display: block;">{{ invoice.date }}</span>
                                    <a class="url-link" :href="invoice.file_url" target="_blank" v-if="invoice.file_url">
                                        <div class="row" v-if="invoice.invoice_number">
                                            <div class="col">
                                                <label style="width: 120px; float: left;">{{profile.invoicing.invoice_number}}</label>
                                                <p data-testid="commissionValueInfoNumber2" style="margin: 2px 0 0 0; float: left;">
                                                    {{invoice.invoice_number}}                       
                                                </p>
                                            </div>
                                        </div>
                                        <div class="row" v-if="invoice.invoice_value">
                                            <div class="col">
                                                <label style="width: 120px; float: left; margin-bottom: 0;">{{profile.accountPage.account.amount}}</label>
                                                <p data-testid="commissionValueInfoAmount2" style="margin: 2px 0 0 0; float: left;">€ {{invoice.invoice_value}}</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>                       
                            </div>
                            <span class="button button--icon button--small" style="float: right; margin: 20px 0 0 10px;" @click="uploadInvoiceModalOpen = true" v-if="!readOnly"><i class="fas fa-file-pdf"></i> {{profile.invoicing.add_invoice}}</span>
                            <span class="button button--icon button--small" style="float: right; margin: 20px 0 0 10px;" @click="uploadQuotationModalOpen = true" v-if="!readOnly"><i class="fas fa-file-pdf"></i> {{profile.invoicing.add_quotation}}</span>
                            <span class="button button--icon button--small" style="float: right; margin: 20px 0 0 10px;" @click="mandateViolationModalOpen = true" v-if="!readOnly && activeMandate && activeMandate.amount && incidentInfo.building_manager_mandate && incidentInfo.incident_status !== 'closed'"><i class="fas fa-exclamation"></i> {{profile.mandates.mandate_violation}}</span>
                        </div>
                        <div class="block block--incident_detail location" style="width: 100%;" v-if="currentAppointment">
                            <section class="incident-head" style="margin-bottom: 10px;">
                                <h1 data-testid="incidentDetailLatest">{{profile.incident_detail.latest_appointment}}</h1>
                            </section>
                            <div class="row">
                                <div class="col">
                                    <div class="incident-info wide">
                                        <div class="row">
                                            <div class="col-6" v-if="incidentInfo.id_appointment">
                                                <label>{{profile.employees.mechanic}}</label>
                                                <p data-testid="incidentDetailMechanic" style="margin-bottom: 0; font-size: 16px; font-weight: 100;" v-if="incidentInfo.id_mechanic">{{incidentInfo.mechanic_name}}</p>
                                                <p data-testid="incidentDetailUnknown" style="margin-bottom: 0; font-size: 16px; font-weight: 100;" v-else>{{profile.incidents.incidenttable.mechanic_unknown}}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <div v-if="incidentInfo.calendar_choice === 'choose_latest_possible'">
                                                    <label v-if="currentAppointmentDate" style="margin: 10px 0 0 0;">{{profile.incident_detail.choose_latest_possible}}</label>
                                                    <p data-testid="incidentDetailDate" style="font-size: 16px; font-weight: 100;">{{currentAppointmentDate}}</p>
                                                </div>
                                                <div v-else>
                                                    <label v-if="currentAppointment" style="margin: 10px 0 0 0;">{{profile.incident_detail.latest_appointment}}</label>
                                                    <p data-testid="incidentDetailCurrent" style="font-size: 16px; font-weight: 100;">{{currentAppointment}}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-3" v-if="currentAppointment && repairInfo.start">
                                                <label>{{profile.incident_detail.start_repair}}</label>
                                                <p data-testid="incidentDetailStart">{{repairInfo.start}}</p>
                                            </div>
                                            <div class="col-sm-3" v-if="currentAppointment && repairInfo.start">
                                                <label>{{profile.incident_detail.end_repair}}</label>
                                                <p data-testid="incidentDetailEnd">{{repairInfo.end}}</p>
                                            </div>
                                            <div class="col-sm-3" v-if="currentAppointment && repairInfo.start">
                                                <label>{{profile.incident_detail.duration_repair}}</label>
                                                <p data-testid="incidentDetailDuration">{{repairInfo.timeWorked}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <MediaDocs :incidentId="incidentId" :incidentInfo="incidentInfo" :media="media" :docs="docs" :mediacount="mediaCount" :movs="movs" :readOnly="readOnly" @getIncidentInfo="getIncidentInfo()" />
                    </div>
                    <div class="col-lg-4">
                        <Notes :incidentInfo="incidentInfo" v-if="incidentInfo.id_incident" :readOnly="readOnly" />
                        <OtherDetails :incidentInfo="incidentInfo" />
                        <div class="block block--incident_detail location" style="width: 100%;">
                            <section class="incident-head" style="margin-bottom: 10px;">
                                <h1 data-testid="incidentDetailAsset">{{profile.assets.assetmodal.place}}</h1>
                            </section>
                            <div class="row">
                                <div class="col">
                                    <a :href="mapsUrl" target="_blank">
                                        <img :src="mapsApiUrl" id="address-map" />
                                    </a>
                                </div>
                            </div>
                            <p style="margin-bottom: 0; font-weight: 500;"><i class="fas fa-cube"></i> {{incidentInfo.asset_name}}</p>
                            <p data-testid="incidentDetailLocation" style="margin-bottom: 0;">
                                {{incidentInfo.place_name}}<br />
                                {{incidentInfo.place_address}}<br />
                                {{incidentInfo.place_zipcode}} {{incidentInfo.place_city}}<br />
                                {{profile.incident_detail.floor_name}}: {{incidentInfo.place_floor_name}}
                            </p>
                        </div>
                    </div>
                </div>
                <div id="calendar" v-if="incidentInfo.id_incident && incidentInfo.incident_status !== 'fixed_agreed_auto' &&
                                         incidentInfo.incident_status !== 'fixed_mechanic' &&
                                         incidentInfo.incident_status !== 'fixed_agreed_reporter' &&
                                         incidentInfo.incident_status !== 'fixed_agreed_repaircompany' &&
                                         incidentInfo.incident_status !== 'closed'">
                    <Schedule   :incidentInfo="incidentInfo"
                                :readOnly="readOnly"
                                :selectedMechanic="selectedMechanic"
                                :mechanics="mechanics"
                                :supplier="true"
                                :mechanicsNoS1mone="mechanicsNoS1mone"
                                @getMechanics="getMechanics"
                                @getIncidentInfo="getIncidentInfo"
                    />
                </div>
            </div>
        </div>
        <Chat :incidentId="incidentId" :incidentInfo="incidentInfo" @getIncidentInfo="getIncidentInfo()" v-if="incidentInfo.id_incident && !incidentInfo.repair_company_no_communication && !incidentInfo.repair_company_only_email"  />
        <RefuseWorkModal v-if="refuseWorkModalOpen" @close="refuseWorkModalOpen = false" @refuse="getIncidentInfo()" :incidentId="incidentInfo.id_incident" :incidentInfo="incidentInfo" />
        <MandateViolationModal v-if="mandateViolationModalOpen" @close="mandateViolationModalOpen = false" @violate="getIncidentInfo()" :incidentInfo="incidentInfo" :mandate="activeMandate" />
        <IncidentLogModal v-if="logIncidentModalOpen" @close="logIncidentModalOpen = false" :incidentId="incidentInfo.id_incident" />
        <ConnectedIncidentsModal v-if="connectedIncidentsModalOpen" @close="connectedIncidentsModalOpen = false" :incidentInfo="incidentInfo" />
        <HistoryModal v-if="historyModalOpen" @close="historyModalOpen = false" :incidentInfo="incidentInfo" :originSupplier="true" />
        <AppointmentEditModal v-if="appointmentModalOpen" @close="appointmentModalOpen = false" @remove="appointmentModalClose(selectedMechanic)" :appointment="selectedAppointment" :incidentId="incidentInfo.id_incident" :mechanicId="selectedMechanic" />
        <StartIncidentModal v-if="startIncidentModalOpen" @close="startIncidentModalOpen = false" @readonly="readOnly = true" @startincident="startIncident()" :incidentInfo="incidentInfo" />
        <FinishIncidentModal v-if="finishIncidentModalOpen" @close="finishIncidentModalOpen = false" :incidentId="incidentInfo.id_incident" :incidentInfo="incidentInfo" :supplier="true" />
        <ManualCheckInModal v-if="manualCheckinModalOpen" @close="manualCheckinModalOpen = false" :incidentId="incidentInfo.id_incident" @checkin="getIncidentInfo()" />
        <ManualCheckOutModal v-if="manualCheckoutModalOpen" @close="manualCheckoutModalOpen = false" :incidentId="incidentInfo.id_incident" @checkout="getIncidentInfo()" :supplier="true" />
        <ManualCheckInOutModal v-if="manualCheckInOutModalOpen" @close="manualCheckInOutModalOpen = false" :incidentId="incidentInfo.id_incident" :appointmentId="incidentInfo.id_appointment" @checkout="getIncidentInfo()" :supplier="true" />
        <ManualPauseModal v-if="manualPauseModalOpen" @close="manualPauseModalOpen = false" :incidentId="incidentInfo.id_incident" @pause="getIncidentInfo()" />
        <UploadMediaModal v-if="uploadMediaModalOpen" @close="uploadMediaModalOpen = false" @success="getIncidentInfo()" :incidentId="incidentInfo.id_incident" :supplier="true" />
        <MandateHistoryModal v-if="mandateHistoryModalOpen" @close="mandateHistoryModalOpen = false" :incidentMandateLog="incidentMandateLog" />
        <UploadQuotationModal v-if="uploadQuotationModalOpen" @close="uploadQuotationModalOpen = false" :incidentId="incidentInfo.id_incident" :incidentInfo="incidentInfo" @success="quotationUploaded()" :isSupplier="true" />
        <UploadInvoiceModal v-if="uploadInvoiceModalOpen" @close="uploadInvoiceModalOpen = false" :incidentId="incidentInfo.id_incident" :incidentInfo="incidentInfo" @success="quotationUploaded()" />
        <RemoveInvoiceModal v-if="removeInvoiceModalOpen" @close="removeInvoiceModalOpen = false" @success="getInvoices()" :incidentInfo="incidentInfo" :selectedInvoice="selectedInvoice" />
        <EditInvoiceModal v-if="editInvoiceModalOpen" @close="editInvoiceModalOpen = false" :incidentId="incidentInfo.id_incident"  :incidentInfo="incidentInfo" :selectedInvoice="selectedInvoice" @success="quotationUploaded()" />
   </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale-supplier.js';
import { mapState } from 'vuex';
import moment from 'moment';
import sortBy from 'lodash/sortBy';
import { EventBus } from '@/js/event-bus.js';
import VueScrollTo from 'vue-scrollto';
import Chat from '@/components/supplier/Chat.vue'
import OtherDetails from '@/components/supplier/OtherDetails.vue';
import MediaDocs from '@/components/supplier/MediaDocs.vue';
import RefuseWorkModal from "@/components/supplier/modals/RefuseWorkModal.vue";
import MandateViolationModal from "@/components/supplier/modals/MandateViolationModal.vue";
import IncidentLogModal from "@/components/modals/IncidentLogModal.vue";
import Schedule from '@/components/incidentdetail/Schedule.vue';
import ConnectedIncidentsModal from "@/components/modals/ConnectedIncidentsModal.vue";
import HistoryModal from "@/components/modals/HistoryModal.vue";
import AppointmentEditModal from "@/components/modals/AppointmentEditModal.vue";
import StartIncidentModal from "@/components/supplier/modals/StartIncidentModal.vue";
import FinishIncidentModal from "@/components/supplier/modals/FinishIncidentModal.vue";
import ManualCheckInModal from "@/components/modals/ManualCheckInModal.vue";
import ManualCheckOutModal from "@/components/modals/ManualCheckOutModal.vue";
import ManualCheckInOutModal from "@/components/modals/ManualCheckInOutModal.vue";
import ManualPauseModal from "@/components/modals/ManualPauseModal.vue";
import UploadMediaModal from "@/components/modals/UploadMediaModal.vue";
import MandateHistoryModal from "@/components/modals/MandateHistoryModal.vue";
import UploadQuotationModal from "@/components/modals/UploadQuotationModal.vue";
import UploadInvoiceModal from "@/components/modals/UploadInvoiceModal.vue";
import RemoveInvoiceModal from "@/components/modals/RemoveInvoiceModal.vue";
import EditInvoiceModal from "@/components/modals/EditInvoiceModal.vue";
import Notes from "@/components/supplier/Notes.vue";
export default {
    mixins: [setLocale],
    components: {
        Chat,
        RefuseWorkModal,
        MandateViolationModal,
        MediaDocs,
        OtherDetails,
        Schedule,
        IncidentLogModal,
        ConnectedIncidentsModal,
        HistoryModal,
        AppointmentEditModal,
        StartIncidentModal,
        FinishIncidentModal,
        ManualCheckInModal,
        ManualCheckOutModal,
        ManualCheckInOutModal,
        ManualPauseModal,
        UploadMediaModal,
        MandateHistoryModal,
        UploadQuotationModal,
        UploadInvoiceModal,
        RemoveInvoiceModal,
        EditInvoiceModal,
        Notes
    },
    computed: mapState(['mapsKey', 'invoices']),
    watch: {
        invoices() {
            this.invoiceList = this.invoices;
        }
    },
    data() {
        return {
            profile: {},
            incidentId: 0,
            incidentInfo: {},
            loading: true,
            mapsApiUrl: '',
            mapsUrl: '',
            mechanics: [],
            mechanicsNoS1mone: [],
            selectedMechanic: null,
            refuseWorkModalOpen: false,
            mandateViolationModalOpen: false,
            logIncidentModalOpen: false,
            connectedIncidentsModalOpen: false,
            appointmentModalOpen: false,
            historyModalOpen: false,
            startIncidentModalOpen: false,
            finishIncidentModalOpen: false,
            manualCheckinModalOpen: false,
            manualCheckoutModalOpen: false,
            manualCheckInOutModalOpen: false,
            manualPauseModalOpen: false,
            uploadMediaModalOpen: false,
            manualCheckIn: false,
            manualCheckOut: false,
            manualCheckInOut: false,
            weekend: false,
            noStatusChange: false,
            newIncident: false,
            readOnly: false,
            readyToFinish: false,
            currentAppointment: '',
            currentAppointmentDate: '',
            repairInfo: {},
            highlightActive: false,
            subButtonsToggled: false,
            activeMandate: {},
            media: [],
            movs: [],
            mediaCount: 0,
            docs: [],
            invoiceList: [],
            violationRemark: {},
            invoiceParty: {},
            invoiceInfoToggled: false,
            mandateHistoryModalOpen: false,
            uploadQuotationModalOpen: false,
            uploadInvoiceModalOpen: false,
            incidentMandateLog: [],
            quotationInfoToggled: false,
            invoicingInfoToggled: false,
            vvevest: false,
            removeInvoiceModalOpen: false,
            selectedInvoice: {},
            editInvoiceModalOpen: false,
            mandateIncreaseTooltip: ''
        }
    },
    created() {
        let queryString = window.location.search;
        this.incidentId = queryString.split('=')[1];
        this.incidentId = this.incidentId.replace('&openchat', '');
        this.getIncidentInfo();
        this.getMandate();
        this.getInvoiceData();
        this.getInvoices();
        document.title = 'S1MONE | ' + this.incidentId;
        setTimeout(function () {
            if (!this.incidentInfo.id_incident) {
                window.location = '/reparateur';
            }
        }.bind(this), 2100);
        EventBus.$on('mandateNote', busObj => {
            this.violationRemark = busObj;
        });
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getIncidentInfo() {
            this.subButtonsToggled = false;
            this.manualCheckIn = false;
            this.manualCheckOut = false;
            this.manualCheckInOut = false;
            this.repairInfo = {};
            this.currentAppointment = '';
            let self = this;
            fetch('/api/v1/incidentcombined?id_incident=' + self.incidentId)
            .then(response => { response.json().then(
                function(data) {
                    data.day = moment(data.last_status_time).locale('nl').format('dddd');
                    data.date = moment(data.last_status_time).locale('nl').format('D MMMM YYYY');
                    data.time = moment(data.last_status_time).locale('nl').format('LT');
                    let created = moment(data.last_status_time).format('DD/MM/YYYY HH:mm:ss');
                    let now = moment().format('DD/MM/YYYY HH:mm:ss');
                    let difference = moment(now,"DD/MM/YYYY HH:mm:ss").diff(moment(created,"DD/MM/YYYY HH:mm:ss"));
                    let differenceObj = self.convertToDays(difference);
                    let differenceString = differenceObj.days + self.profile.datetime.days + ' ' + differenceObj.hours + self.profile.datetime.hours + ' ' +  differenceObj.minutes  + self.profile.datetime.minutes;
                    data.overtime = differenceString;
                    if (differenceObj.days < 1) {
                        data.overtimeStatus = 'green';
                    }
                    if (differenceObj.days > 1) {
                        data.overtimeStatus = 'orange';
                    }
                    if (differenceObj.days > 3) {
                        data.overtimeStatus = 'red';
                    }
                    if (data.start_time) {
                        var date = moment(data.start_time).locale('nl').format('D MMMM');
                        var start = moment(data.start_time).format();
                        start = moment(data.start_time).format('D/MM, H:mm');
                        var end = moment(data.end_time).format();
                        end = moment(data.end_time).format('H:mm');
                        self.currentAppointment = start + ' - ' + end;
                        self.currentAppointmentDate = date;
                    }
                    let gmapsAddress    = data.place_address.replace(/ /g , "+")+"+"+data.place_city.replace(/ /g , "+");
                    self.mapsApiUrl     = 'https://maps.googleapis.com/maps/api/staticmap?markers='+
                                           gmapsAddress+
                                            '&zoom=16&size=250x150&key=' + self.mapsKey;
                    self.mapsUrl        = "https://www.google.com/maps/place/"+gmapsAddress;
                    self.selectedRepairCompany = data.id_repair_company;
                    if (data.id_mechanic) {
                        self.selectedMechanic = data.id_mechanic;
                    }
                    else {
                        self.selectedMechanic = 0;
                    }
                    //Action buttons handling
                    if (!data.serviced_by_rep_user_id) {
                        if ((data.serviced_by_rep_user_id === null && data.incident_status === 'maintenance_checkin_mechanic') ||
                            (data.serviced_by_rep_user_id === null && data.incident_status === 'fixed_agreed_auto') ||
                            (data.serviced_by_rep_user_id === null && data.incident_status === 'paused_mechanic') ||
                            (data.serviced_by_rep_user_id === null && data.incident_status === 'pending_repaircompany')
                        ) {
                            self.noStatusChange = true;
                        }
                        if (data.incident_status ==='new_' ||
                            (data.serviced_by_rep_user_id === null && data.incident_status === 'new_repaircompany') ||
                            (data.serviced_by_rep_user_id === null && data.incident_status === 'maintenance_checkin_mechanic') ||
                            (data.serviced_by_rep_user_id === null && data.incident_status === 'fixed_agreed_auto') ||
                            (data.serviced_by_rep_user_id === null && data.incident_status === 'paused_mechanic') ||
                            (data.serviced_by_rep_user_id === null && data.incident_status === 'pending_repaircompany')
                        )  {
                            self.newIncident = true;
                            self.startIncidentModalOpen = true;
                            self.startIncidentModalOpen = setTimeout(()=>{ self.startIncidentModalOpen = false; document.querySelector('body').classList.remove('modal-open'); }, 3000);
                            self.readOnly = setTimeout(()=>{ self.readOnly = true; }, 3000);
                        }
                    }
                    if (data.incident_status !== 'new_' && data.incident_status !== 'closed') {
                        self.readyToFinish = true;
                        if (data.serviced_by_rep_user_id === null) {
                            self.readOnly = true;
                        }
                    }
                    if (data.incident_status === 'closed' || data.incident_status === 'cancelled' || data.incident_status === 'returned_to_buildingmanager') {
                        self.readOnly = true;
                    }
                    if (data.has_order && data.id_appointment && !data.id_mechanic) {
                        self.readOnly = false;
                    }
                    if (data.incident_status=='fixed_mechanic' || data.incident_status=='fixed_agreed_reporter' || data.incident_status=='fixed_agreed_auto' || data.incident_status=='fixed_agreed_repaircompany' || data.incident_status=='closed') {
                        fetch('/api/v1/incidentcombined/repairinfo?id_incident=' + self.incidentId)
                        .then(response => { response.json().then(
                            function(data) {
                                if (data.timeWorked) {
                                    var start = moment(data.start).format();
                                    start = moment(data.start).format('D/MM/YYYY HH:mm');
                                    var end = moment(data.end).format();
                                    end = moment(data.end).format('D/MM/YYYY HH:mm');
                                    var timeWorked = self.createWorkedString(data.timeWorked);
                                    data.start = start;
                                    data.end = end;
                                    data.timeWorked = timeWorked;
                                    self.repairInfo = data;
                                }
                            });
                        });
                    }
                    if (data.incident_status === 'planned' || data.incident_status=== 'pending_mechanic' || data.incident_status=== 'paused_mechanic' ) {
                        self.manualCheckIn = true;
                    }
                    if (data.incident_status === 'on_location_mechanic') {
                        self.manualCheckOut = true;
                    }
                    if (data.incident_status === 'planned') {
                        self.manualCheckInOut = true;
                    }
                    if (data.pictures) {
                        self.media = [];
                        self.movs = [];
                        self.docs = [];
                        for (var i = 0; i < data.pictures.length; i++) {
                            let media = data.pictures[i];
                            if (self.locale === 'nl') {
                                media.date = moment(media.created).locale('nl').format('D MMM YYYY HH:mm:ss');
                            }
                            else {
                                media.date = moment(media.created).format('MMM D YYYY HH:mm:ss');
                            }
                            if ((!media.file_url.includes('.pdf') && media.content_type.toLowerCase() !== 'application/pdf') && media.content_type !== 'video/quicktime') {
                                media.popover = false;
                                media.rotate = 1;
                                if (media.type === 'incident_reporter') {
                                    media.order = 1;
                                }
                                if (media.type === 'incident_building_manager') {
                                    media.order = 2;
                                }
                                if (media.type === 'incident_mechanic') {
                                    media.order = 3;
                                }
                                media.selected = false;
                                if (!media.content_type) {
                                    media.content_type = 'image/jpeg';
                                }
                                self.media.push(media);
                                self.media = sortBy(self.media, 'order');
                            }
                            if (media.file_url.includes('.pdf') || media.content_type.toLowerCase() === 'application/pdf') {
                                let n = media.file_url.lastIndexOf('/');
                                let fileName = media.file_url.substring(n + 1);
                                media.pdf = true;
                                media.filename = fileName;
                                media.selected = false;
                                if (media.type !== 'invoice_pdf_supplier') {
                                    self.docs.push(media);
                                }
                                if (media.type === 'quotation_pdf_supplier') {
                                    data.quotationUrl = media.file_url;
                                }
                            }
                            if (media.content_type === 'video/quicktime') {
                                self.movs.push(media);
                            }
                        }
                    }
                    self.docs = sortBy(self.docs, 'id_shiro_user');
                    if (data.place_floor_name === 'VVEVEST') {
                        self.vvevest = true;
                        if (self.locale === 'nl') {
                            data.place_floor_name = 'Niet van toepassing'
                        }
                        if (self.locale === 'en') {
                            data.place_floor_name = 'Not applicable'
                        }
                    }
                    self.incidentInfo = data;
                    if (self.incidentInfo.quotation_value_supplier && self.incidentInfo.quotation_value_supplier.toString().includes('.')) {
                        self.incidentInfo.quotation_value_supplier = (Math.round(self.incidentInfo.quotation_value_supplier * 100) / 100).toFixed(2);
                        self.incidentInfo.quotation_value_supplier = self.incidentInfo.quotation_value_supplier.toString().replaceAll('.', ',');
                    }
                    if (self.incidentInfo.mandate_warning === 'mandate_increase') {
                        self.getIncidentMandates();
                    }
                    self.loading = false;
                    console.log(self.incidentInfo);
                });
            });
        },
        getIncidentMandates() {
            let self = this;
            fetch('/api/v1/incidentmandate/byincident?id_incident=' + this.incidentInfo.id_incident)
            .then(response => { response.json().then(
                function(data) {
                    if (data.length > 1) {
                        self.mandateIncreaseTooltip = self.profile.mandates.from + '€' + data[1].amount + ' ' + self.profile.mandates.to +  '€' + data[0].amount;  
                    }
                });
            });
        },
        getInvoices() {
            this.removeInvoiceModalOpen = false;
            let self = this;
            fetch('/api/v1/order/getsupplierinvoices?id_incident=' + this.incidentId)
            .then(response => { response.json().then(
                function(data) {
                    if (data.length) {
                        let invoices = [];
                        for (var i = 0; i < data.length; i++) {
                            let invoice = data[i];
                            if (self.locale === 'nl') {
                                invoice.date = moment(invoice.created).locale('nl').format('D MMM YYYY HH:mm:ss');
                                invoice.description = 'Factuur-' + invoice.invoice_number;
                            }
                            else {
                                invoice.date = moment(invoice.created).format('MMM D YYYY HH:mm:ss');
                                invoice.description = 'Invoice-' + invoice.invoice_number;
                            }
                            if (invoice.invoice_value && invoice.invoice_value.toString().includes('.')) {
                                invoice.invoice_value = (Math.round(invoice.invoice_value * 100) / 100).toFixed(2);
                                invoice.invoice_value = invoice.invoice_value.toString().replaceAll('.', ',');
                            } 
                            invoices.push(invoice);
                        }
                        self.invoiceList = invoices;
                    }   
                    else {
                        self.invoiceList = [];
                    }                 
                });
            });
        },
        getInvoiceData() {
            let self = this;
            fetch('/api/v1/invoice_data/byincident?id_incident=' + this.incidentId)
            .then(response => { response.json().then(
                function(data) {
                    self.invoiceParty = data;
                    if (self.invoiceParty.created) {
                        self.invoiceParty.date = moment(self.invoiceParty.created).format('DD/MM/YYYY HH:mm:ss');
                    }
                });
            });
        },
        getMandate() {
            let self = this;
            fetch('/api/v1/incidentmandate/byincident?id_incident=' + this.incidentId)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.length; i++) {
                        let mandate = data[i];
                        mandate.date = moment(mandate.created).format('DD/MM/YYYY HH:mm:ss');
                        if (mandate.active) {
                            self.activeMandate = mandate;
                        }
                    }
                    if (!data.length) {
                        self.activeMandate = {
                            amount: 0
                        }
                    }
                    if (self.activeMandate && self.activeMandate.id_incident_mandate) {
                        if (self.activeMandate.amount.toString().includes('.')) {
                            self.activeMandate.amount = (Math.round(self.activeMandate.amount * 100) / 100).toFixed(2);
                            self.activeMandate.amount = self.activeMandate.amount.toString().replaceAll('.', ',');
                        }
                    }
                    self.incidentMandateLog = data;
                });
            });
        },
        createWorkedString(timeWorked) {
            var result;
            var days;
            var hours;
            var minutes;
            let daysStr;
            let hoursStr;
            let minutesStr;
            if (!days) {
                daysStr   = this.profile.datetime.days;
                hoursStr  = this.profile.datetime.hours;
                minutesStr = this.profile.datetime.minutes;
            }

            days = Math.floor(timeWorked/86400000);
            days = days < 0 ? 0 : days;

            timeWorked = timeWorked - days*86400000;
            hours = Math.floor(timeWorked/3600000);
            hours = hours < 0 ? 0 : hours;

            timeWorked = timeWorked - hours*3600000;
            minutes = Math.floor(timeWorked/60000);
            minutes = minutes < 0 ? 0 : minutes;

            result = days+daysStr+" ";
            result += hours+hoursStr+" ";
            result += minutes+minutesStr;

            return result;
        },
        convertToDays(milliSeconds){
            let days = Math.floor(milliSeconds/(86400 * 1000));
            milliSeconds -= days*(86400*1000);
            let hours = Math.floor(milliSeconds/(60 * 60 * 1000 ));
            milliSeconds -= hours * (60 * 60 * 1000);
            let minutes = Math.floor(milliSeconds/(60 * 1000));
            return {
                days, hours, minutes
            }
        },
        selectMedia(type, index) {
            if (type === 'prev') {
                index = index - 1;
                for (var i = 0; i < this.media.length; i++) {
                    this.media[i].popover = false;
                    this.media[index].popover = true;
                }
            }
            if (type === 'next') {
                index = index + 1;
                for (var j = 0; j < this.media.length; j++) {
                    this.media[j].popover = false;
                    this.media[index].popover = true;
                }
            }
        },
        closePopover() {
            for (var i = 0; i < this.incidentInfo.pictures.length; i++) {
                this.incidentInfo.pictures[i].popover = false;
            }
        },
        dontShowHighlight() {
            if (!localStorage.getItem('S1-highlight-plan_appointment')) {
                localStorage.setItem('S1-highlight-plan_appointment', true);
                this.highlightActive = false;
            }
        },
        getMechanics() {
            let self = this;
            fetch('/api/v1/mechanic/byincident?id_incident=' + this.incidentId)
            .then(response => { response.json().then(
                function(data) {
                    self.mechanics = [];
                    self.mechanicsNoS1mone = [];
                    if (self.locale === 'nl') {
                        self.mechanics[0] = { 'id_mechanic': 0, 'name': 'Niet van toepassing' };
                    }
                    else {
                        self.mechanics[0] = { 'id_mechanic': 0, 'name': 'Not applicable' };
                    }
                    for (var i = 0; i < data.mechanics.length; i++) {
                        let mechanic = data.mechanics[i];
                        if (mechanic.only_email) {
                            self.mechanicsNoS1mone.push(mechanic);
                        }
                        else {
                            self.mechanics.push(mechanic);
                        }
                    }
                });
            });
        },
        startIncident() {
            this.startIncidentModalOpen = false;
            this.readOnly = false;
            this.handleNewStatus('getInfo');
            let successText = this.profile.incident_detail.incident_start;
            this.$vToastify.success(successText, this.profile.toast.started);
        },
        scrollCalendar() {
            var options = {
                offset: -60
            }
            let element = document.getElementById('calendar');
            VueScrollTo.scrollTo(element, 200, options);
        },
        handleNewStatus(type) {
            let status;
            if (this.noStatusChange) {
                status = this.incidentInfo.incident_status;
            }
            else {
                status = 'pending_repaircompany';
            }
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            data.append('message', '');
            data.append('status', status);
            let self = this;
            fetch('/api/v1/incident/changestatus', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.newIncident = false;
                self.readOnly = false;
                if (!self.noStatusChange) {
                    self.incidentInfo.incident_status = 'pending_repaircompany';
                    self.readyToFinish = true;
                    self.getIncidentInfo();
                }
                if (type === 'getInfo') {
                    setTimeout(function () {
                        self.readOnly = false;
                        self.getIncidentInfo();
                    }.bind(this), 2000);
                }
            });
        },
        saveAdminReady() {
            const data = new URLSearchParams();
            data.append('id_incident', this.incidentId);
            data.append('admin_done', true);
            let self = this;
            fetch('/api/v1/incident/setadmindone', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$router.push('/supplier');
                let successText = self.profile.dashboard.building_manager.message_table.incident_ID + ' <b>' + self.incidentId + '</b> ' + self.profile.toast.closed_successfully;
                self.$vToastify.success(successText, self.profile.toast.incident_finished);

            });
        },
        rotateMedia(item) {
            item.rotate++;
            if (item.rotate === 5) {
                item.rotate = 1;
            }
        },
        quotationUploaded() {
            this.uploadQuotationModalOpen = false;
            this.uploadInvoiceModalOpen = false;
            this.getIncidentInfo();
        },
        removeInvoice(invoice) {
            this.selectedInvoice = invoice;
            this.removeInvoiceModalOpen = true;
        },
        editInvoice(invoice) {
            this.selectedInvoice = invoice;
            this.editInvoiceModalOpen = true;
        }
    }
}
</script>
