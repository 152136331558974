<template>
    <div class="modal-wrapper" v-if="profile.incidents">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-plus"></i>{{profile.mandates.mandate_increase}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner" id="inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="increaseMandate()" style="overflow: hidden;">
                    <label>{{profile.mandates.current_mandate_amount}}</label>
                    <p style="margin-bottom: 5px;">€ {{mandate.amount}} <span v-if="mandate.in_btw">({{profile.mandates.in_btw}})</span><span v-else>({{profile.mandates.out_btw}})</span></p>  
                    <label v-if="incidentInfo.quotation_value_supplier"><span v-if="incidentInfo.mandate_warning === 'target_price'" style="font-weight: 500;">{{profile.invoicing.target_price}}</span><span style="font-weight: 500;" v-else>{{profile.invoicing.quotation_amount}}</span></label>
                    <p v-if="incidentInfo.quotation_value_supplier">€ {{incidentInfo.quotation_value_supplier}}</p>  
                    <br />              
                    <div class="row">
                        <div class="col">
                            <label>{{profile.mandates.new_amount}}
                                <i class="fas fa-info-circle" 
                                   v-tooltip.right="mandate.in_btw ? profile.incident_detail.mandate_violation_tooltip_incl : profile.incident_detail.mandate_violation_tooltip_excl" 
                                   style="cursor: pointer;">
                                </i>
                            </label>
                            <input type="text" class="form-control" v-model="fields.amount" :placeholder="profile.mandates.enter_amount" @input="editAmount()">
                        </div>
                    </div>
                    <div class="notification notification--error" v-if="increaseError">{{ profile.mandates.increase_error }}</div>
                    <button type="submit" class="button button--icon"><i class="fas fa-plus"></i>{{profile.mandates.submit_mandate_increase}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    props: ['incidentInfo', 'mandate'],
    data() {
        return {
            profile: {},
            fields: {
                amount: null,
            },
            increaseError: false
        }
    },
    created() {
        this.$store.dispatch('updateMandateAdded', false);
        if (!this.mandate) {
            this.getMandate();
        }
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        editAmount() {
            this.fields.amount = this.fields.amount.replace(/[A-Za-z!@#$%^&*()]/g, '');
        },
        getMandate() {
            let self = this;
            fetch('/api/v1/incidentmandate/byincident?id_incident=' + this.incidentInfo.id_incident)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.length; i++) {
                        let mandate = data[i];
                        if (mandate.active) {
                            self.mandate = mandate;
                        }
                    }
                    if (!data.length) {
                        self.mandate = {
                            amount: 0
                        }
                    }
                    if (self.mandate && self.mandate.id_incident_mandate) {
                        if (self.mandate.amount.toString().includes('.')) {
                            self.mandate.amount = (Math.round(self.mandate.amount * 100) / 100).toFixed(2);
                        }
                    }
                });
            });
        },
        increaseMandate() {
            if (this.fields.amount < this.mandate.amount) {
                this.increaseError = true;
            } else {
                this.increaseError = false;
            }
            if (!this.increaseError) {
                this.fields.amount = this.fields.amount.replaceAll(',', '.');
                const data = new URLSearchParams();
                data.append('id_incident', this.incidentInfo.id_incident);
                data.append('amount', this.fields.amount);
                let self = this;
                fetch('/api/v1/incidentmandate/increase', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.$emit('success');
                });
            }     
        }
    }
}
</script>
