<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa-regular fa-tag"></i>{{profile.tags.tags_incident}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <div class="row" v-if="incidentTags.length">
                    <div class="col">
                        <div class="tags">
                            <label style="display: block;">{{profile.tags.applied_tags}}</label>
                            <span v-for="tag in incidentTags" class="tag" :key="tag.id_label">
                                <p>{{ tag.value }}</p>
                                <span class="remove-tag" @click="removeConnectedTag(tag)"><i class="fas fa-times"></i></span>
                            </span>
                        </div>   
                    </div>
                </div>
                <br />
                <div class="row">
                    <div class="col">
                        <label>Tags toepassen</label>
                        <div class="dropdown-section">
                            <span class="dropdown" :class="{active: tagsDropdownToggled}" v-on:click="tagsDropdownToggled = !tagsDropdownToggled">
                                <span v-if="!selectedTag.id_label" class="placeholder">{{profile.tags.choose_tag}}</span>
                                <span v-if="selectedTag.id_label" class="selected">{{selectedTag.value}}</span>
                                <i class="fas fa-chevron-down chevron"></i>
                            </span>
                            <div class="dropdown-list search" v-if="tagsDropdownToggled">
                                <input type="text" @input="e => tagsSearchTerm = e.target.value" :value="tagsSearchTerm" placeholder="Zoek een tag" @keyup="searchTags()" />
                                <ul>
                                    <li v-for="tag in tags" v-on:click="selectTag(tag)" :key="tag.id_label" v-show="!tempTags.length && !tagsNoResults && tag.active">{{tag.value}}</li>
                                    <li v-for="(tag, index) in tempTags" v-on:click="selectTag(tag)" :key="index" v-show="tempTags.length">{{tag.value}}</li>
                                    <li v-if="tagsNoResults" class="no-results" style="padding-right: 15px;">{{profile.tags.tag_exists}} <b>'{{ tagsSearchTerm }}'</b>. {{profile.tags.tag_exists2}}
                                        <span class="button button--small button--icon" style="float: right; margin-top: 15px; width: auto;" @click=createTag()><i class="fa-regular fa-tag"></i> {{profile.tags.tag_add_apply}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>         
                <span class="button button--icon" style="float: right; margin-top: 15px;" @click=connectTag()><i class="fa-regular fa-tag"></i> {{profile.tags.apply_tag}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],   
    props: ['incidentInfo'], 
    data: function () {
        return {
           tags: [],
           incidentTags: [],
           tagsDropdownToggled: false,
           selectedTag: {},
           tagsSearchTerm: '',
           tempTags: [],
           tagsNoResults: false
        }
    },
    created() {
        document.querySelector('body').classList.add('modal-open');
        this.getIncidentTags();
    },
    methods: {
        getIncidentTags() {
            let self = this;
            fetch('/api/v1/incidents/' + this.incidentInfo.id_incident + '/labels')
	        .then(response => { response.json().then(
	            function(data) {
                    self.incidentTags = data;
                    self.incidentTags = sortBy(self.incidentTags, 'value');
                    self.getTags();
				});
	        });
        },
        getTags() {
            this.tags = [];
            this.removeTagModalOpen = false;
            let self = this;
            fetch('/api/v1/buildingManager/' + this.incidentInfo.id_building_manager + '/labels')
	        .then(response => { response.json().then(
	            function(data) {
                    self.tags = data;
                    self.tags = self.tags.filter(item2 =>
                        !self.incidentTags.some(item1 => item1.id_label === item2.id_label)
                    );
                    self.tags = sortBy(self.tags, 'value');
				});
	        });
        },
        selectTag(tag) {
            this.selectedTag = tag;
            this.tagsDropdownToggled = false;
        },
        connectTag() {
            console.log(this.selectedTag);
            const params = new URLSearchParams();
            if (this.selectedTag.value) {
                params.append('label_text', this.selectedTag.value);
            }
            else {
                params.append('label_text', this.tagsSearchTerm);
            }
            let self = this;
            fetch('/api/v1/incidents/' + this.incidentInfo.id_incident + '/labels', {
                method: 'PUT',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.tagsSearchTerm = '';
                self.selectedTag = {};
                self.tagsNoResults = false;
                self.tagsDropdownToggled = false;
                self.getTags();
                self.getIncidentTags();
            });
        },
        removeConnectedTag(tag) {
            let self = this;
            fetch('/api/v1/incidents/' + this.incidentInfo.id_incident + '/labels/' + tag.id_label, {
                method: 'DELETE'
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getIncidentTags();
            });
        },
        searchTags() {
            this.tempTags = [];
            if (this.tagsSearchTerm.length > 1) {
                let searchTerm = this.tagsSearchTerm.toLowerCase();
                for (var i = 0; i < this.tags.length; i++) {
                    let tag = this.tags[i];
                    let tagName = tag.value.toLowerCase();
                    if (tagName.includes(searchTerm)) {
                        this.tempTags.push(tag);
                    }
                    if (!this.tempTags.length) {
                        this.tagsNoResults = true;
                    }
                    else {
                        this.tagsNoResults = false;
                    }
                }
            }
            else {
                this.tagsNoResults = false;
            }
        },
        createTag() {
            const params = new URLSearchParams();
            params.append('label_text', this.tagsSearchTerm);
            let self = this;
            fetch('/api/v1/buildingManager/' + this.incidentInfo.id_building_manager + '/labels', {
                method: 'PUT',
                body: params
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.selectedTag = data;
                console.log(self.selectedTag);
                self.connectTag();
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
