<template>
    <div class="block block--account block--border" style="min-height: 0; overflow: hidden;">
        <div class="account-info">
            <div class="account-info">
                <h4 style="margin-top: 4px; margin-bottom: 0;">Tags</h4> 
                <span class="edit-button icon" style="margin-top: 10px;" @click="startAdding()" v-if="accountInfo.building_manager_admin && !addTag"><i class="fa-solid fa-plus"></i></span>
            </div>
            <div v-if="tags.length" class="tags">
                <span v-for="tag in tags" class="tag" :key="tag.id_label">
                    <p>{{ tag.value }}</p>
                    <span class="remove-tag" @click="selectTagToRemove(tag)"><i class="fas fa-times"></i></span>
                </span>
            </div>
            <div v-if="addTag" style="margin-top: 15px; clear: both;">
                <label>{{profile.tags.create_tag}}</label>
                <input type="text" class="form-control" v-model="newTag" ref="newtag" />
                <div class="row" v-if="duplicateError">
                    <div class="col">
                        <div class="notification notification--error" style="margin: 0 0 15px 0; float: left; padding-right: 10px;">{{profile.tags.already_exists}}</div>
                    </div>
                </div>
                <span class="button button--icon" style="float: right;" @click=createTag()><i class="fa-regular fa-tag"></i> {{profile.tags.create_tag}}</span>
            </div>
        </div>
        <RemoveTagModal v-if="removeTagModalOpen" :accountInfo="accountInfo" :tagToRemove="tagToRemove" @removed="getTags()" @close="removeTagModalOpen = false" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import RemoveTagModal from "@/components/modals/RemoveTagModal.vue";
import sortBy from 'lodash/sortBy';

export default {
    mixins: [setLocale],
    components: {
        RemoveTagModal
    },
    data: function () {
        return {
           tags: [],
           addTag: false,
           newTag: '',
           tagToRemove: {},
           removeTagModalOpen: false,
           duplicateError: false
        }
    },
    props: ['accountInfo', 'admin'],
    created() {
        this.getTags();
    },
    methods: {
        startAdding() {
            this.addTag = true;
            setTimeout(function () {
                this.$refs.newtag.focus();
            }.bind(this), 200);
        },
        getTags() {
            this.removeTagModalOpen = false;
            let self = this;
            fetch('/api/v1/buildingManager/' + this.accountInfo.id_building_manager + '/labels')
	        .then(response => { response.json().then(
	            function(data) {
                    self.tags = data;
                    self.tags = sortBy(self.tags, tag => tag.value.toLowerCase());
				});
	        });
        },
        createTag() {
            this.duplicateError = false;
            const params = new URLSearchParams();
            params.append('label_text', this.newTag);
            let self = this;
            fetch('/api/v1/buildingManager/' + this.accountInfo.id_building_manager + '/labels', {
                method: 'PUT',
                body: params
            })
            .then(resp => {
                if (!resp.ok) {
                    if (resp.status === 422) {
                        self.duplicateError = true;
                    }
                }
                else {
                    self.addTag = false;
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.getTags();
                }
            });
        },
        selectTagToRemove(tag) {
            this.tagToRemove = tag;
            this.removeTagModalOpen = true;
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
