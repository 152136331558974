<template>
    <div class="modal-wrapper" v-if="profile.incident_detail">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa fa-times"></i>{{profile.tags.remove_tag}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <p>{{profile.tags.remove_tag_sure}} "<b>{{ tagToRemove.value }}</b>" {{profile.tags.remove_tag_sure2}}
                    <br />
                </p>
                <button class="button button--icon button--right" v-on:click="removeTag()"><i class="fas fa-trash-alt"></i>{{profile.tags.remove_tag}}</button>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
export default {
    mixins: [setLocale],   
    props: ['tagToRemove', 'accountInfo'], 
    created() {
        document.querySelector('body').classList.add('modal-open');
    },
    methods: {
        removeTag() {
            let self = this;
            fetch('/api/v1/buildingManager/' + this.accountInfo.id_building_manager + '/labels/' + this.tagToRemove.id_label, {
                method: 'DELETE'
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('removed');
            });
        },
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        }
    }
}
</script>
