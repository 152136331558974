<template>
    <div class="modal-wrapper" v-if="profile.actionable">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fas fa-pen"></i>Categorie bewerken</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner higher">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="saveCategory()" style="overflow: hidden; padding-bottom: 10px;">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:selectedCategory.dutch_name.length}">
                                <label>{{profile.nationalities.dutch}} *</label>
                                <input type="text" class="form-control" v-model="selectedCategory.dutch_name" disabled>
                            </div>
                        </div>   
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:selectedCategory.english_name.length}">
                                <label>{{profile.nationalities.english}}</label>
                                <input type="text" data-testid="editEnglishName" class="form-control" v-model="selectedCategory.english_name">
                            </div>
                        </div>              
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:selectedCategory.ukrainian_name.length}">
                                <label>{{profile.nationalities.ukrainian}}</label>
                                <input type="text" class="form-control" v-model="selectedCategory.ukrainian_name">
                            </div>
                        </div>   
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:selectedCategory.russian_name.length}">
                                <label>{{profile.nationalities.russian}}</label>
                                <input type="text" class="form-control" v-model="selectedCategory.russian_name">
                            </div>
                        </div>              
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:selectedCategory.german_name.length}">
                                <label>{{profile.nationalities.german}}</label>
                                <input type="text" class="form-control" v-model="selectedCategory.german_name">
                            </div>
                        </div>      
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:selectedCategory.french_name.length}">
                                <label>{{profile.nationalities.french}}</label>
                                <input type="text" class="form-control" v-model="selectedCategory.french_name">
                            </div>
                        </div>              
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:selectedCategory.italian_name.length}">
                                <label>{{profile.nationalities.italian}}</label>
                                <input type="text" class="form-control" v-model="selectedCategory.italian_name">
                            </div>
                        </div>      
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:selectedCategory.polish_name.length}">
                                <label>{{profile.nationalities.polish}}</label>
                                <input type="text" class="form-control" v-model="selectedCategory.polish_name">
                            </div>
                        </div>              
                    </div>
                    <div class="row">     
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:selectedCategory.contact_phone.length}">
                                <label>Telefoon</label>
                                <input type="text" class="form-control" v-model="selectedCategory.contact_phone">
                            </div>
                        </div>    
                        <div class="col-md-6">
                            <div class="form-field" :class="{focus:selectedCategory.contact_url.length}">
                                <label>URL</label>
                                <input type="text" class="form-control" v-model="selectedCategory.contact_url">
                            </div>
                        </div>         
                    </div>
                    <div class="row">              
                        <div class="col-md-6">
                            <div class="form-field focus">
                                <label>Volgorde</label>
                                <input type="text" class="form-control" v-model="selectedCategory.list_order">
                            </div> 
                        </div>    
                    </div> 
                    <div class="row">
                        <div class="col-md-4">
                            <label>Media gewild</label>
                            <select class="form-select" v-model="selectedCategory.media_wanted">
                                <option value="true">Ja</option>
                                <option value="false">Nee</option>
                            </select>     
                        </div>      
                        <div class="col-md-4" v-if="selectedCategory.media_wanted === 'true' || selectedCategory.media_wanted === true">
                            <label>Media verplicht</label>
                            <select class="form-select" v-model="selectedCategory.media_required">
                                <option value="true">Ja</option>
                                <option value="false">Nee</option>
                            </select>  
                        </div> 
                        <div class="col-md-4">
                            <label>Actief</label>
                            <select class="form-select" v-model="selectedCategory.active">
                                <option value="true">Ja</option>
                                <option value="false">Nee</option>
                            </select>     
                        </div>   
                    </div>      
                    <button type="submit" data-testid="saveCategoryButton" class="button button--cta button--icon"><i class="fas fa-save"></i>Categorie opslaan</button>
                    <span class="button button--icon" data-testid="removeCategoryButton" style="margin-right: 10px;" @click="removeCategory()"><i class="fas fa-trash-alt"></i>Categorie verwijderen</span>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    props: ['selectedCategory'],
    data() {
        return {
            profile: {},
        }
    },
    created() {
        this.tempContact = this.selectedContact;
        document.querySelector('.main-content').style.display = 'block';
        document.querySelector('body').classList.add('modal-open');
        let self = this;
        document.onkeydown = function(evt) {
            evt = evt || window.event;
            if (evt.keyCode == 27) {
                self.closeModal();
            }
        };
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        saveCategory() {
            if (this.selectedCategory.media_wanted === 'false') {
                this.selectedCategory.media_required = 'false';
            }
            const data = new URLSearchParams();
            let paramIdIncidentCategory = 'data[' + this.selectedCategory.id_incident_category + '][id_default_incident_category]';
            let paramDutchName = 'data[' + this.selectedCategory.id_incident_category + '][dutch_name]';
            let paramEnglishName = 'data[' + this.selectedCategory.id_incident_category + '][english_name]';
            let paramUkrainianName = 'data[' + this.selectedCategory.id_incident_category + '][ukrainian_name]';
            let paramRussianName = 'data[' + this.selectedCategory.id_incident_category + '][russian_name]';
            let paramPolishName = 'data[' + this.selectedCategory.id_incident_category + '][polish_name]';
            let paramItalianName = 'data[' + this.selectedCategory.id_incident_category + '][italian_name]';
            let paramGermanName = 'data[' + this.selectedCategory.id_incident_category + '][german_name]';
            let paramFrenchName = 'data[' + this.selectedCategory.id_incident_category + '][french_name]';
            let paramContactPhone = 'data[' + this.selectedCategory.id_incident_category + '][contact_phone]';
            let paramContactUrl = 'data[' + this.selectedCategory.id_incident_category + '][contact_url]';
            let paramListOrder = 'data[' + this.selectedCategory.id_incident_category + '][list_order]';
            let paramMediaWanted = 'data[' + this.selectedCategory.id_incident_category + '][media_wanted]';
            let paramMediaRequired = 'data[' + this.selectedCategory.id_incident_category + '][media_required]';
            let paramActive = 'data[' + this.selectedCategory.id_incident_category + '][active]';
            let paramBmId = 'data[' + this.selectedCategory.id_incident_category + '][id_building_manager]';
            data.append('action', 'edit');
            data.append(paramIdIncidentCategory, this.selectedCategory.id_default_incident_category);
            data.append(paramBmId, this.selectedCategory.id_building_manager);
            data.append(paramDutchName, this.selectedCategory.dutch_name);
            data.append(paramEnglishName, this.selectedCategory.english_name);
            data.append(paramUkrainianName, this.selectedCategory.ukrainian_name);
            data.append(paramRussianName, this.selectedCategory.russian_name);
            data.append(paramPolishName, this.selectedCategory.polish_name);
            data.append(paramItalianName, this.selectedCategory.italian_name);
            data.append(paramGermanName, this.selectedCategory.german_name);
            data.append(paramFrenchName, this.selectedCategory.french_name);
            data.append(paramContactPhone, this.selectedCategory.contact_phone);
            data.append(paramContactUrl, this.selectedCategory.contact_url);
            data.append(paramListOrder, this.selectedCategory.list_order);
            data.append(paramMediaWanted, this.selectedCategory.media_wanted);
            data.append(paramMediaRequired, this.selectedCategory.media_required);
            data.append(paramActive, this.selectedCategory.active);
            let self = this;
            fetch('/api/v1/incident_category', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('success');
                document.querySelector('body').classList.remove('modal-open');
            });
        },
        removeCategory() {
            let self = this;
            const data = new URLSearchParams();
            let paramIdIncidentCategory = 'data[' + this.selectedCategory.id_incident_category + '][id_incident_category]';
            let paramBmId = 'data[' + this.selectedCategory.id_incident_category + '][id_building_manager]';
            data.append('action', 'remove');
            data.append(paramIdIncidentCategory, this.selectedCategory.id_incident_category);
            data.append(paramBmId, this.selectedCategory.id_building_manager);        
            fetch('/api/v1/incident_category', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.json();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.$emit('success');
                document.querySelector('body').classList.remove('modal-open');
            });
        }
    }
}
</script>
