<template>
    <div class="modal-wrapper" v-if="profile.incidents">
        <div class="modal">
            <div class="modal-header">
                <h3><i class="fa fa-exclamation"></i>{{profile.mandates.mandate_violation}}</h3>
                <img src="@/assets/img/logo-white.png" class="logo" />
            </div>
            <div class="modal-inner" id="inner">
                <span class="close" @click="closeModal()"><i class="fas fa-times"></i></span>
                <form v-on:submit.prevent="violateIncident()" style="overflow: hidden;">
                    <label>{{profile.mandates.mandate_amount}}</label>
                    <p>€ {{mandate.amount}} <span v-if="mandate.in_btw">({{profile.mandates.in_btw}})</span><span v-else>({{profile.mandates.out_btw}})</span></p>
                    <div class="row">
                        <div class="col">
                            <label style="margin-bottom: 10px;">Type</label>
                            <label class="radio-container" style="margin-bottom: 5px;">
                                <input data-testid="violateOfferRadio" type="radio" name="violationType" value="quotation_amount" id="quotation" v-model="fields.type">
                                <label for="quotation">{{profile.invoicing.quotation_amount}}</label>
                            </label>
                            <label class="radio-container">
                                <input data-testid="violateTargetRadio" type="radio" name="violationType" value="target_price" id="price" v-model="fields.type">
                                <label for="price">{{profile.invoicing.target_price}}</label>
                            </label>
                        </div>
                    </div>                 
                    <div class="row">
                        <div class="col">
                            <label>{{profile.accountPage.account.amount}}
                                <i class="fas fa-info-circle" 
                                   v-tooltip.right="mandate.in_btw ? profile.incident_detail.mandate_violation_tooltip_incl : profile.incident_detail.mandate_violation_tooltip_excl" 
                                   style="cursor: pointer;">
                                </i>
                            </label>
                            <input data-testid="violateAmountInput" type="text" class="form-control" v-model="fields.amount" @input="calculateViolationAmount()">
                        </div>
                    </div>
                    <div class="row" v-if="violationAmount">
                        <div class="col">
                            <label>{{ profile.mandates.violation_amount }}</label>
                            <p data-testid="violateDifferenceText">{{ violationAmount }}</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label>{{profile.invoicing.quotation_number}}</label>
                            <input data-testid="violateOfferInput" type="text" class="form-control" v-model="fields.quotationNumber">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <label>{{profile.incident_detail.remark}}</label>
                            <textarea data-testid="violateCommentInput" class="form-control" v-model="fields.remark"></textarea>
                        </div>
                    </div>
                    <div class="notification notification--error" v-if="amountError">{{ profile.mandates.increase_error }}</div>
                    <button type="submit" data-testid="violateSubmitButton" class="button button--icon" ><i class="fa fa-exclamation"></i>{{profile.mandates.submit_violation}}</button>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';

export default {
    mixins: [setLocale],
    props: ['incidentInfo', 'mandate'],
    data() {
        return {
            profile: {},
            fields: {
                type: 'quotation_amount',
                amount: null,
                quotationNumber: null,
                remark: '',
            },
            violationAmount: null,
            amountError: false
        }
    },
    created() {
        if (!this.mandate) {
            this.getMandate();
        }
        if (this.incidentInfo.quotation_number_supplier) {
            this.fields.quotationNumber = this.incidentInfo.quotation_number_supplier;
        }
        if (this.incidentInfo.quotation_value_supplier) {
            this.fields.amount = this.incidentInfo.quotation_value_supplier;
        }
        this.$store.dispatch('updateMandateAdded', false);
    },
    methods: {
        closeModal() {
            this.$emit('close');
            document.querySelector('body').classList.remove('modal-open');
        },
        getMandate() {
            let self = this;
            fetch('/api/v1/incidentmandate/byincident?id_incident=' + this.incidentInfo.id_incident)
            .then(response => { response.json().then(
                function(data) {
                    for (var i = 0; i < data.length; i++) {
                        let mandate = data[i];
                        if (mandate.active) {
                            self.mandate = mandate;
                        }
                    }
                    if (!data.length) {
                        self.mandate = {
                            amount: 0
                        }
                    }
                    if (self.mandate && self.mandate.id_incident_mandate) {
                        if (self.mandate.amount.toString().includes('.')) {
                            self.mandate.amount = (Math.round(self.mandate.amount * 100) / 100).toFixed(2);
                        }
                    }
                });
            });
        },
        calculateViolationAmount() {
            this.fields.amount = this.fields.amount.replace(/[A-Za-z!@#$%^&*()]/g, '');
            let violationAmount = this.fields.amount - this.mandate.amount;
            if (violationAmount > 0) {
                this.violationAmount = violationAmount;
            }
            else {
                this.violationAmount = null;
            }
        },
        violateIncident() {
            let amountNumber;
            if (typeof this.fields.amount === "string") {
                amountNumber = parseFloat(this.fields.amount.replace(',', '.'));
            }
            else {
                amountNumber = this.fields.amount;
            }
            if (amountNumber < this.mandate.amount) {
                    this.amountError = true;
                }
            else {
                if (typeof this.fields.amount === "string") {
                    this.fields.amount = this.fields.amount.replaceAll(',', '.');
                }
                const data = new URLSearchParams();
                data.append('id_incident', this.incidentInfo.id_incident);
                data.append('quotation_value_supplier', this.fields.amount);
                data.append('mandate_warning', this.fields.type);
                data.append('quotation_number_supplier', this.fields.quotationNumber);
                data.append('comment', this.fields.remark);
                let self = this;
                fetch('/api/v1/order/suppliermandatewarning', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.json();
                }).then(function(data) {
                    self.$gtag.event('click', {
                        event_category: 'Button',
                        event_label: 'Incident detail - Mandaatoverschrijding',
                    });
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.$emit('success');
                });
            }
        }
    }
}
</script>
