<template>
    <div>
        <div class="main-content" v-if="profile.repair_companies">
            <div class="container">
                <div class="row">
                    <div class="col">
                        <a class="back-link" @click="$router.go(-1)"><i class="fas fa-chevron-left"></i>{{profile.nav.back_link}}</a>
                    </div>
                </div>
                <div class="block block--account">
                    <div class="profile-card" style="border-right: 0;">
                        <div class="row">
                            <div class="col-3">
                                <div class="profile-pic-wrapper">
                                    <img src="@/assets/icons/loading.gif" v-if="logoLoading" class="loader" style="position: relative; width: 100%; float: right; right: auto;" />
                                    <div v-else>
                                        <span class="edit" data-testid="photoAddButton" @click="editLogo()" v-if="repairCompanyInfo.profile_image_loc && repairCompanyInfo.profile_image_loc !== '/images/no-profile-img.png' && admin"><i class="fas fa-pencil-alt"></i></span>
                                        <span class="seperator" v-if="repairCompanyInfo.profile_image_loc && repairCompanyInfo.profile_image_loc !== '/images/no-profile-img.png' && admin">|</span>
                                        <span class="remove" data-testid="photoRemoveButton" @click="removeLogo()"  v-if="repairCompanyInfo.profile_image_loc && repairCompanyInfo.profile_image_loc !== '/images/no-profile-img.png' && admin"><i class="far fa-trash-alt"></i></span>
                                        <img :src="repairCompanyInfo.profile_image_loc" class="profile-pic" v-if="repairCompanyInfo.profile_image_loc && repairCompanyInfo.profile_image_loc !== '/images/no-profile-img.png'" />
                                        <span class="add" data-testid="photoEditButton" @click="editLogo()" v-if="!repairCompanyInfo.profile_image_loc || repairCompanyInfo.profile_image_loc === '/images/no-profile-img.png' && admin"><i class="fa-solid fa-plus"></i></span>
                                        <div class="profile-pic placeholder" v-if="!repairCompanyInfo.profile_image_loc || repairCompanyInfo.profile_image_loc === '/images/no-profile-img.png'">
                                            <i class="far fa-image" data-testid="photoImage"></i>
                                        </div>
                                        <input type="file" id="profile-image-upload" data-testid="photoInput" style="display: none;" @change="uploadLogo($event)"/>
                                    </div>
                                </div>
                            </div>
                            <div class="col-9">
                                <h4>{{profile.incidents.incidenttable.repair_company}}</h4>
                                <div v-if="nameEdit">
                                    <input type="text" class="form-control" data-testid="repEditNameInput" v-model="repairCompanyInfo.name" style="margin-top: 10px;" />
                                </div>
                                <h2 data-testid="supplierName" v-else>{{repairCompanyInfo.name}}</h2>
                            </div>
                        </div>
                    </div>
                    <span class="edit-button icon" data-testid="repEditNameButton" @click="nameEdit = true" v-if="admin && no_simone && !nameEdit" style="position: absolute; top: 0;
                    bottom: 0; right: 60px; margin: auto; height: 35px;"><i class="fas fa-pencil-alt"></i></span>
                    <span class="edit-button icon" data-testid="repNameSaveButton" @click="saveName()" v-if="admin && no_simone && nameEdit" style="position: absolute; top: 0;
                    bottom: 0; right: 60px; margin: auto; height: 35px;"><i class="fas fa-save"></i></span>
                    <span class="edit-button icon" data-testid="supplierDeleteButton" @click="removeRepairCompanyModalOpen = true" v-if="admin" style="position: absolute; top: 0;
                    bottom: 0; margin: auto; height: 35px;"><i class="far fa-trash-alt"></i></span>
                </div>
                <div class="row">
                    <div :class="{'col-md-9': mechanics.length, 'col': !mechanics.length}">
                        <div class="block block--location_detail" style="padding-bottom: 0;">
                            <div v-if="!loading">
                                <section class="incident-head">
                                    <h1 data-testid="repEditTitle">{{profile.accountPage.account.general}}</h1>
                                    <span class="no_simone" v-if="no_simone" :class="{right: !admin}">{{profile.incident_detail.no_s1mone}}</span>
                                    <span class="no_simone serviceteam" v-if="serviceTeam">{{profile.accountPage.account.own_service}}</span>
                                </section>
                                <section class="location-contents">
                                    <div class="location-info">
                                        <div class="info-row" data-testid="supplierEmailEditButton" @click.stop="emailEdit = true">
                                            <label>{{profile.repair_companies.repaircompanytable.company_email}} <i class="fas fa-info-circle" v-tooltip.right="profile.repair_companies.repaircompanytable.company_email_tooltip" style="cursor: pointer;"></i>
                                                <span class="edit-button edit-button--small" v-if="!emailEdit && no_simone"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="supplierEmailSave" @click="saveEmail()" v-if="emailEdit && no_simone"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="emailEdit && no_simone">
                                                <input type="text" data-testid="repEditEmail" class="form-control" v-model="repairCompanyInfo.email" />
                                            </div>
                                            <div v-else>
                                                <p data-testid="supplierEmailText">{{repairCompanyInfo.email}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row" data-testid="supplierPhoneEditButton" @click="phoneEdit = true">
                                            <label>{{profile.repair_companies.repaircompanytable.phone}}
                                                <span class="edit-button edit-button--small" v-if="!phoneEdit && no_simone"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="supplierPhoneSave" @click="savePhone()" v-if="phoneEdit && no_simone"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="phoneEdit && no_simone">
                                                <input type="text" data-testid="repEditPhone" class="form-control" v-model="repairCompanyInfo.phone" :class="{valid: validPhone, error: invalidPhone}" @input="validatePhone()" />
                                            </div>
                                            <div v-else>
                                                <p data-testid="supplierPhoneText">{{repairCompanyInfo.phone}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row" data-testid="supplierMobileEditButton" @click.stop="mobileEdit = true">
                                            <label>{{profile.repair_companies.repaircompanytable.mobile}}
                                                <span class="edit-button edit-button--small" v-if="!mobileEdit && no_simone"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="supplierMobileSave" @click="saveMobile()" v-if="mobileEdit && no_simone"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="mobileEdit && no_simone">
                                                <input type="text" data-testid="repEditMobile" class="form-control" v-model="repairCompanyInfo.mobile" :class="{valid: validMobile, error: invalidMobile}" @input="validateMobile()" />
                                            </div>
                                            <div v-else>
                                                <p data-testid="supplierMobileText" v-if="repairCompanyInfo.mobile !== null">{{repairCompanyInfo.mobile}}</p>
                                                <p v-else>&nbsp;</p>
                                            </div>
                                        </div>
                                        <div class="info-row" data-testid="supplierCorrEditButton" @click.stop="emailAddressesEdit = true">
                                            <label>{{profile.repair_companies.repaircompanytable.corrEmail}} <i class="fas fa-info-circle" v-tooltip.right="profile.repair_companies.repaircompanytable.corrEmail_tooltip" style="cursor: pointer;"></i>
                                                <span class="edit-button edit-button--small" v-if="!emailAddressesEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="supplierCorrSave" @click="saveEmailAddresses()" v-if="emailAddressesEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="emailAddressesEdit">
                                                <input type="email" data-testid="repEditCorrEmail" class="form-control" v-model="repairCompanyInfo.email_addresses" />
                                            </div>
                                            <div v-else>
                                                <p data-testid="supplierCorrospondentText">{{repairCompanyInfo.email_addresses}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row" data-testid="supplierContactEditButton" @click.stop="contactEdit = true">
                                            <label>{{profile.repair_companies.repaircompanytable.contact_name}}
                                                <span class="edit-button edit-button--small" v-if="!contactEdit && no_simone"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="supplierContactSave" @click="saveContactPerson()" v-if="contactEdit && no_simone"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="contactEdit && no_simone">
                                                <input type="text" data-testid="repEditContactName" class="form-control" v-model="repairCompanyInfo.contact_name" :disabled="!no_simone" />
                                            </div>
                                            <div v-else>
                                                <p data-testid="supplierContactText">{{repairCompanyInfo.contact_name}}</p>
                                            </div>
                                        </div>
                                        <div class="info-row" v-if="repairCompanyInfo.country === 'other'">
                                            <label>{{profile.repair_companies.repaircompanydetail.repair_company_country}}</label>
                                            <p data-testid="supplierCountryText" v-if="repairCompanyInfo.country_name">{{repairCompanyInfo.country_name}}</p>
                                            <p data-testid="supplierCountryText" v-else>{{countryToText(repairCompanyInfo.country)}}<i class="fas fa-info-circle" v-tooltip.right="profile.repair_companies.repaircompanytable.country_tooltip" style="cursor: pointer; margin-left: 4px;"></i></p>
                                        </div>
                                        <div class="info-row">
                                            <label v-if="repairCompanyInfo.country === 'NL'">{{profile.repair_companies.repaircompanytable.kvk}}</label>
                                            <label v-if="repairCompanyInfo.country === 'other'">{{profile.repair_companies.repaircompanytable.foreign_kvk}}</label>
                                            <p data-testid="supplierKvkText">{{repairCompanyInfo.kvk}}</p>
                                        </div>
                                        <div class="info-row" data-testid="supplierGlnEditButton" @click.stop="glnEdit = true">
                                            <label>GLN <i class="fas fa-info-circle" v-tooltip.right="profile.repair_companies.repaircompanytable.gln_tooltip" style="cursor: pointer;"></i>
                                                <span class="edit-button edit-button--small" v-if="!glnEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="supplierGlnSave" @click="saveGLN()" v-if="glnEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="glnEdit">
                                                <input type="text" name="GLN" class="form-control" v-model="repairCompanyInfo.gln" @keypress="isNumber($event)" @keyup="validateGLN()" :class="{valid: validGLN}" maxlength="20" />
                                            </div>
                                            <div v-else>
                                                <p v-if="repairCompanyInfo.gln">{{repairCompanyInfo.gln}}</p>
                                                <p v-else>&nbsp;</p>                                            
                                            </div>
                                        </div>
                                        <div class="info-row last" data-testid="supplierDescriptionEditButton" @click.stop="descriptionEdit = true">
                                            <label>{{profile.repair_companies.repaircompanytable.extra_info}}
                                                <span class="edit-button edit-button--small" v-if="!descriptionEdit"><i class="fas fa-pencil-alt"></i></span>
                                                <span class="edit-button edit-button--small" data-testid="supplierDescriptionSave" @click="saveDescription()" v-if="descriptionEdit"><i class="far fa-save"></i></span>
                                            </label>
                                            <div v-if="descriptionEdit">
                                                <textarea class="form-control" data-testid="repEditDescription" v-model="repairCompanyInfo.description" />
                                            </div>
                                            <div v-else>
                                                <p data-testid="supplierDescriptionText" v-if="repairCompanyInfo.description !== 'null'" style="white-space: normal; margin-bottom: 0;">{{repairCompanyInfo.description}}</p>
                                                <p v-else>&nbsp;</p>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <img src="@/assets/icons/loading.gif" v-if="loading" class="loader" />
                        </div>
                        <div class="block block--location_detail" v-if="!serviceTeam" style="padding-bottom: 0;">
                            <div class="location-info">
                                <h4 style="margin-top: 0;">{{profile.accountPage.account.communication}}</h4>
                                <div class="row" v-if="!validCorrEmail">
                                    <div class="col">
                                        <div class="notification notification--warning" style="margin-bottom: 20px;">{{profile.repair_companies.repaircompanydetail.comm_not}}</div>
                                    </div>
                                </div>
                                <div>
                                    <div class="row">
                                        <div class="col" style="cursor: pointer;">
                                            <div class="switch-wrapper switch-wrapper--full" :class="{disabled:!admin || !validCorrEmail, active: repairCompanyInfo.only_email}">
                                                <div class="inner" @click.stop="saveOnlyEmail()">
                                                    <i class="fa-regular fa-envelope"></i>
                                                    <p>{{profile.repair_companies.repaircompanydetail.onlyemail}} <i class="fas fa-info-circle" v-tooltip.right="profile.repair_companies.repaircompanydetail.onlyemail_tooltip" style="cursor: pointer;"></i></p>
                                                    <span class="label">{{profile.switches.only_email}}</span>
                                                    <label class="switch">
                                                        <span class="slider round" data-testid="repEmailSlider"></span>
                                                    </label>
                                                </div>    
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col" style="cursor: pointer;">
                                            <div class="switch-wrapper switch-wrapper--full" :class="{disabled:!admin || !validCorrEmail, active: repairCompanyInfo.no_communication}">
                                                <div class="inner" @click.stop="saveNoComm()">
                                                    <i class="fa-regular fa-ban"></i>
                                                    <p>{{profile.repair_companies.repaircompanydetail.nocommunication}} <i class="fas fa-info-circle" v-tooltip.right="profile.repair_companies.repaircompanydetail.nocommunication_tooltip" style="cursor: pointer;"></i></p>
                                                    <span class="label">{{profile.switches.no_comm}}</span>
                                                    <label class="switch">
                                                        <span class="slider round" data-testid="repCommunicationSlider"></span>
                                                    </label>
                                                </div>    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3" v-if="mechanics.length">
                        <div class="block block--mechanics-list">
                            <div v-if="!loading">
                                <h3>{{profile.mechanics.mechanictable.tablename}}</h3>
                                <div v-for="(mechanic, index) in mechanics" :key="index" class="asset-collection">
                                    <div class="mechanic-item">
                                        <h4>{{mechanic.name}}</h4>
                                        <p>{{mechanic.description}}</p>
                                    </div>
                                </div>
                            </div>
                            <img src="@/assets/icons/loading.gif" v-if="loading" class="loader" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <RemoveRepairCompanyModal v-if="removeRepairCompanyModalOpen" @close="removeRepairCompanyModalOpen = false" :repairCompanyId="repairCompanyId" />
    </div>
</template>

<script>
import { setLocale } from '@/mixins/setLocale.js';
import { mapState } from 'vuex';
import sortBy from 'lodash/sortBy';
import RemoveRepairCompanyModal from "@/components/modals/RemoveRepairCompanyModal.vue";

export default {
    mixins: [setLocale],
    components: {
        RemoveRepairCompanyModal
    },
    data() {
        return {
            profile: {},
            loading: true,
            logoLoading: false,
            repairCompanyId: 0,
            repairCompanyInfo: {},
            mechanics: [],
            emailEdit: false,
            phoneEdit: false,
            mobileEdit: false,
            emailAddressesEdit: false,
            contactEdit: false,
            glnEdit: false,
            descriptionEdit: false,
            removeRepairCompanyModalOpen: false,
            userName: null,
            validPhone: false,
            invalidPhone: false,
            validMobile: false,
            invalidMobile: false,
            validGLN: false,
            no_simone: false,
            serviceTeam: false,
            admin: false,
            validCorrEmail: false,
            nameEdit: false,
        }
    },
    computed: mapState(['vve']),
    created() {
        let queryString = window.location.search;
        this.repairCompanyId = queryString.split('=')[1];
        this.getRepairCompanyInfo();
        this.getMechanics();
        this.getCurrentUser();
    },
    methods: {
        track() {
            this.$gtag.pageview(this.$route);
        },
        getRepairCompanyInfo() {
            let self = this;
            fetch('/api/v1/repaircompany/byid?id_repair_company=' + this.repairCompanyId)
            .then(response => { response.json().then(
                function(data) {
                    self.repairCompanyInfo = data;
                    if (self.repairCompanyInfo.only_email || self.repairCompanyInfo.no_communication) {
                        self.no_simone = true;
                    }
                    else {
                        self.no_simone = false;
                    }
                    if (self.repairCompanyInfo.phone === 'null' || !self.repairCompanyInfo.phone) {
                        self.repairCompanyInfo.phone = '';
                    }
                    if (self.repairCompanyInfo.mobile === 'null' || !self.repairCompanyInfo.mobile) {
                        self.repairCompanyInfo.mobile = '';
                    }
                    if (data.email_addresses) {
                        if (data.email_addresses.includes('@') && data.email_addresses.includes('.')) {
                            self.validCorrEmail = true;
                        }
                    }
                    else {
                        self.validCorrEmail = false;
                    }
                    self.loading = false;
                    document.title = 'S1MONE | ' + self.repairCompanyInfo.name;
                    self.getAccountInfo();
                    console.log(self.repairCompanyInfo);
                });
            });
        },
        getAccountInfo() {
            let self = this;
            fetch('/api/v1/userandbuildingmanager')
            .then(response => { response.json().then(
                function(data) {
                    self.admin = data.building_manager_admin;
                    if (self.repairCompanyInfo.id_repair_company === data.canbe_id_repair_company) {
                        self.serviceTeam = true;
                    }
                });
            });
        },
        getMechanics() {
            let self = this;
            fetch('/api/v1/mechanic/byrepaircompany?id_repair_company=' + this.repairCompanyId)
            .then(response => { response.json().then(
                function(data) {
                    self.mechanics = data.data;
                    self.mechanics = sortBy(self.mechanics, 'name');
                });
            });
        },
        getCurrentUser() {
            let self = this;
            fetch('/api/v1/getcurrentuser')
	        .then(response => { response.json().then(
	            function(data) {
					self.userName = data.name;
				});
	        });
        },
        saveName() {
            const data = new URLSearchParams();
            data.append('id_repair_company', this.repairCompanyId);
            data.append('name', this.repairCompanyInfo.name);
            let self = this;
            fetch('/api/v1/repaircompany/updatename', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.nameEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveOnlyEmail() {
            if (this.admin && this.validCorrEmail) {
                this.repairCompanyInfo.only_email = !this.repairCompanyInfo.only_email;
                this.repairCompanyInfo.no_communication = false;
                const onlyEmailData = new URLSearchParams();
                onlyEmailData.append('id_repair_company', this.repairCompanyId);
                onlyEmailData.append('only_email', this.repairCompanyInfo.only_email);
                let self = this;
                fetch('/api/v1/repaircompany/updateonlyemail', {
                    method: 'post',
                    body: onlyEmailData
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                });
                const noCommData = new URLSearchParams();
                noCommData.append('id_repair_company', this.repairCompanyId);
                noCommData.append('no_communication', this.repairCompanyInfo.no_communication);
                fetch('/api/v1/repaircompany/updatenocommunication', {
                    method: 'post',
                    body: noCommData
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.getRepairCompanyInfo();
                });
            }
        },
        saveNoComm() {
            if (this.admin && this.validCorrEmail) {
                this.repairCompanyInfo.no_communication = !this.repairCompanyInfo.no_communication;
                this.repairCompanyInfo.only_email = false;
                const noCommData = new URLSearchParams();
                noCommData.append('id_repair_company', this.repairCompanyId);
                noCommData.append('no_communication', this.repairCompanyInfo.no_communication);
                let self = this;
                fetch('/api/v1/repaircompany/updatenocommunication', {
                    method: 'post',
                    body: noCommData
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                });
                const onlyEmailData = new URLSearchParams();
                onlyEmailData.append('id_repair_company', this.repairCompanyId);
                onlyEmailData.append('only_email', this.repairCompanyInfo.only_email);
                fetch('/api/v1/repaircompany/updateonlyemail', {
                    method: 'post',
                    body: onlyEmailData
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                    self.getRepairCompanyInfo();
                });
            }
        },
        saveContactPerson() {
            const data = new URLSearchParams();
            data.append('id_repair_company', this.repairCompanyId);
            data.append('contactname', this.repairCompanyInfo.contact_name);
            let self = this;
            fetch('/api/v1/repaircompany/updatecontactname', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.contactEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        savePhone() {
            const data = new URLSearchParams();
            data.append('id_repair_company', this.repairCompanyId);
            data.append('phone', this.repairCompanyInfo.phone);
            let self = this;
            fetch('/api/v1/repaircompany/updatephone', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.phoneEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveMobile() {
            const data = new URLSearchParams();
            data.append('id_repair_company', this.repairCompanyId);
            data.append('mobile', this.repairCompanyInfo.mobile);
            let self = this;
            fetch('/api/v1/repaircompany/updatemobile', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.mobileEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveEmail() {
            const data = new URLSearchParams();
            data.append('id_repair_company', this.repairCompanyId);
            data.append('email', this.repairCompanyInfo.email);
            let self = this;
            fetch('/api/v1/repaircompany/updateemail', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.emailEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveEmailAddresses() {
            const data = new URLSearchParams();
            data.append('id_repair_company', this.repairCompanyId);
            data.append('email_addresses', this.repairCompanyInfo.email_addresses);
            if (this.repairCompanyInfo.email_addresses.includes('@') && this.repairCompanyInfo.email_addresses.includes('.')) {
                this.validCorrEmail = true;
            }
            else {
                this.validCorrEmail = false;
            }
            let self = this;
            fetch('/api/v1/repaircompany/updateemailaddresses', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.emailAddressesEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        saveDescription() {
            const data = new URLSearchParams();
            data.append('id_repair_company', this.repairCompanyId);
            data.append('description', this.repairCompanyInfo.description);
            let self = this;
            fetch('/api/v1/repaircompany/updatedescription', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.descriptionEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getRepairCompanyInfo();
            });
        },
        editLogo() {
            document.getElementById('profile-image-upload').click();
        },
        uploadLogo($event) {
            this.logoLoading = true;
            if ($event.target.files.length == 1) {
                const data = new FormData();
                data.append('file', $event.target.files[0]);
                data.append('id_repair_company', this.repairCompanyId);
                let self = this;
                fetch('/api/v1/fileupload/uploadlogo', {
                    method: 'post',
                    body: data
                }).then((resp) => {return resp.text();
                }).then(function(data) {
                    self.repairCompanyInfo.profile_image_loc = data;
                    self.logoLoading = false;
                });
            }
        },
        removeLogo() {
            const data = new URLSearchParams();
            data.append('id_repair_company', this.repairCompanyId);
            let self = this;
            fetch('/api/v1/fileupload/deletelogo', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
                self.getRepairCompanyInfo();
            });
        },
        validateMobile() {
            if (this.repairCompanyInfo.mobile) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.repairCompanyInfo.mobile)) {
                    this.validMobile = true;
                    this.invalidMobile = false;
                }
                else {
                    this.invalidMobile = true;
                    this.validMobile = false;
                }
            }
            else {
                this.invalidMobile = false;
                this.validMobile = false;

            }
        },
        validatePhone() {
            if (this.repairCompanyInfo.phone) {
                let pattern = new RegExp(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                if (pattern.test(this.repairCompanyInfo.phone)) {
                    this.validPhone = true;
                    this.invalidPhone = false;
                }
                else {
                    this.invalidPhone = true;
                    this.validPhone = false;
                }
            }
            else {
                this.invalidPhone = false;
                this.validPhone = false;
            }
        },
        validateGLN() {
            if (this.repairCompanyInfo.gln) {
                if (this.repairCompanyInfo.gln.length) {
                    this.validGLN = true;
                }
                else {
                    this.validGLN = false;
                }
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        saveGLN() {
            const data = new URLSearchParams();
            data.append('id_repair_company', this.repairCompanyId);
            data.append('gln', this.repairCompanyInfo.gln);
            let self = this;
            fetch('/api/v1/repaircompany/updategln', {
                method: 'post',
                body: data
            }).then((resp) => {return resp.text();
            }).then(function(data) {
                self.glnEdit = false;
                self.$vToastify.success(self.profile.toast.edited, self.profile.toast.changes_saved);
            });
        },
        countryToText(country) {
            if (country === 'NL') {
                return this.profile.places.placetable.NL;
            }
            else {
                return this.profile.repair_companies.repaircompanytable.other;
            }
        },
    }
}
</script>
